import React from "react";
import { useState, useEffect, useRef } from "react";

const Phonk = () => {
    return (
        <div className="Phonk">
            <video className="phonk-video" controls>
                <source src={`https://${window.location.hostname}/api/phonk`} type="audio/mpeg" />
                Your browser does not support the audio element.
            </video>
            <h1>NIGHT DRIVE PHONK MIX (LXST CXNTURY TYPE) #3 - PHONKONAUT</h1>
        </div>
    );
};

export default Phonk;
