import React, { useEffect, useRef, useCallback } from "react";
import { useState } from "react";
import "./Music.css";
import axios from "axios";
import Cookies from "js-cookie";

import { useLocalStorage } from "../hooks/useLocalStorage.js";
import { Image } from "image-js";

import ContextMenu from "../components/OptionsMenu.js";

import IconAdapter from "../icons/IconAdapter.jsx";

const Music = () => {
  const [contextMenu, setContextMenu] = useState({
    visible: false,
    position: { x: 0, y: 0 },
    options: [],
  });

  const [playlistEditorModalOpened, setPlaylistEditorModalOpened] =
    useState(false);

  const [curPlaylistGradientStyle, setCurPlaylistGradientStyle] =
    useState(null);

  const [curPlayingId, setCurPlayingId] = useState([0, 0, 0]);

  const [curPlaylistId, setPlaylistId] = useState(null);

  const [curPage, setCurPage] = useState("main");

  const [curOpenedPlaylistData, setCurOpenedPlaylistData] = useState(null);

  const [playlistsData, setPlaylistsData] = useState([]);

  const [fieldSearch, setFieldSearch] = useState("");
  const [musicSearchField, setMusicSearchField] = useState("");

  const curPlaylistImageRef = useRef(null);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`; // Формат MM:SS
  };

  const rgbToHsl = (r, g, b) => {
    r /= 255;
    g /= 255;
    b /= 255;
    const max = Math.max(r, g, b),
      min = Math.min(r, g, b);
    let h,
      s,
      l = (max + min) / 2;
    if (max == min) {
      h = s = 0; // achromatic
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }
      h /= 6;
    }
    return { h, s, l };
  };

  const [musicTopListData, setMusicTopListData] = useState(null);

  const [musicSearchData, setMusicSearchData] = useState(null);

  const [currentlyPlaying, setCurrentlyPlaying] = useState(false);
  const [curTrack, setCurTrack] = useState(null);

  const [fileSelectorText, setFileSelectorText] = useState("Выбери файл");
  const [selectedMusicFile, setSelectedMusicFile] = useState(null);
  const [uploadTrackTitle, setUploadTrackTitle] = useState("");

  const addMediaFileInputRef = useRef();
  const playerRef = useRef();

  const playlistTabRef = useRef();

  const refreshMusicTop = async () => {
    const formData = new FormData();
    formData.append("session", Cookies.get("authToken"));
    await axios
      .post(
        `https://${window.location.hostname}/api/v1/data/get_music_top`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then(({ data }) => {
        console.log("Music top update");
        setMusicTopListData(data);
      })
      .catch((error) => {
        const { status, data } = error.response;
        console.error("Response Status:", status, "Response Data:", data);
      });
  };

  const refreshSearch = async () => {
    if (musicSearchField === "") {
      setCurPage("main");
      return;
    }
    setCurPage("search");
    const formData = new FormData();
    formData.append("session", Cookies.get("authToken"));
    formData.append("search", musicSearchField);
    await axios
      .post(
        `https://${window.location.hostname}/api/v1/data/search_music`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then(({ data }) => {
        console.log("Music search update");
        console.warn(data);
        setMusicSearchData(data);
      })
      .catch((error) => {
        const { status, data } = error.response;
        console.error("Response Status:", status, "Response Data:", data);
      });
  };

  const refresh_my_playlists = async () => {
    const formData = new FormData();
    formData.append("session", Cookies.get("authToken"));
    await axios
      .post(
        `https://${window.location.hostname}/api/v1/data/get_my_playlists`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then(({ data }) => {
        console.log("Music playlists update");
        setPlaylistsData(data);
      })
      .catch((error) => {
        const { status, data } = error.response;
        console.error("Response Status:", status, "Response Data:", data);
      });
  };

  const create_play_list = async () => {
    const formData = new FormData();
    formData.append("session", Cookies.get("authToken"));
    formData.append("name", `Плейлист №${playlistsData.length + 1}`);
    await axios
      .post(
        `https://${window.location.hostname}/api/v1/data/create_playlist`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then(({ data }) => {
        console.log("Created playlist");
        refresh_my_playlists();
      })
      .catch((error) => {
        const { status, data } = error.response;
        console.error("Response Status:", status, "Response Data:", data);
      });
  };

  const handleDeletePlaylist = async (playlist_id) => {
    const formData = new FormData();
    formData.append("session", Cookies.get("authToken"));
    formData.append("target_playlist_id", playlist_id);
    await axios
      .post(
        `https://${window.location.hostname}/api/v1/data/delete_playlist`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then(({ data }) => {
        console.log("Deleted playlist");
        refresh_my_playlists();
      })
      .catch((error) => {
        const { status, data } = error.response;
        console.error("Response Status:", status, "Response Data:", data);
      });
  };

  const handleSongRightClick = (event, options) => {
    event.preventDefault();
    setContextMenu({
      visible: true,
      position: { x: event.clientX, y: event.clientY },
      options,
    });
  };

  const closeContextMenu = () => {
    setContextMenu({ ...contextMenu, visible: false });
  };

  const handlePlayTrack = (track_url, track_title) => {
    if (
      playerRef.current.src !==
      `https://${window.location.hostname}/api${track_url}`
    ) {
      playerRef.current.src = `https://${window.location.hostname}/api${track_url}`;
      playerRef.current.play();
    } else {
      if (playerRef.current.paused) {
        playerRef.current.play();
      } else {
        playerRef.current.pause();
      }
    }
    setCurrentlyPlaying(!playerRef.current.paused);
    setCurTrack(track_title);
  };

  const request_playlist = async (playlist_id) => {
    const target_playlist = playlistsData.find((p) => p.id === playlist_id);
    setCurOpenedPlaylistData(target_playlist);
    setCurPage("playlist");
    setPlaylistEditorName(target_playlist.title);
  };

  useEffect(() => {
    if (curOpenedPlaylistData) {
      const target_playlist = playlistsData.find(
        (p) => p.id === curOpenedPlaylistData.id
      );
      setCurOpenedPlaylistData(target_playlist);
    }
  }, [playlistsData]);

  const handleAddTrackToPlaylist = async (track_id, playlist_id) => {
    const formData = new FormData();
    formData.append("session", Cookies.get("authToken"));
    formData.append("track_id", track_id);
    formData.append("playlist_id", playlist_id);
    await axios
      .post(
        `https://${window.location.hostname}/api/v1/data/add_track_to_playlist`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then(({ data }) => {
        console.log("Music added to playlist");
        refresh_my_playlists();
      })
      .catch((error) => {
        const { status, data } = error.response;
        console.error("Response Status:", status, "Response Data:", data);
      });
  };

  const handleRemoveTrackFromPlaylist = async (
    track_id,
    track_index,
    playlist_id
  ) => {
    const formData = new FormData();
    formData.append("session", Cookies.get("authToken"));
    formData.append("track_id", track_id);
    formData.append("track_index", track_index);
    formData.append("playlist_id", playlist_id);
    await axios
      .post(
        `https://${window.location.hostname}/api/v1/data/remove_track_from_playlist`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then(({ data }) => {
        console.log("Music removed from playlist");
        refresh_my_playlists();
        request_playlist(curOpenedPlaylistData.id);
      })
      .catch((error) => {
        const { status, data } = error.response;
        console.error("Response Status:", status, "Response Data:", data);
      });
  };

  const handleLikeTrack = async (track_id) => {
    const formData = new FormData();
    formData.append("session", Cookies.get("authToken"));
    formData.append("music_id", track_id);
    await axios
      .post(
        `https://${window.location.hostname}/api/v1/data/like_music`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then(({ data }) => {
        console.log("Music liked");
        refresh_my_playlists();
        switch (curPage) {
          case "home":
            refreshMusicTop();
            break;
          case "search":
            refreshSearch();
            break;
          case "playlist":
            request_playlist(curOpenedPlaylistData.id);
            break;
        }
      })
      .catch((error) => {
        const { status, data } = error.response;
        console.error("Response Status:", status, "Response Data:", data);
      });
  };

  const handleMediaAdd = async (event) => {
    const file = event.target.files[0];
    if (file.size > 20 * 1024 * 1024) {
      alert("Файл слишком большой. Максимальный размер - 20 МБ.");
      return;
    }

    setFileSelectorText(file.name);
    const arrayBuffer = await file.arrayBuffer();

    setSelectedMusicFile(file);
  };

  const uploadMusic = async (event) => {
    const formData = new FormData();
    formData.append("session", Cookies.get("authToken"));
    formData.append("music_title", uploadTrackTitle);
    formData.append("music_file", selectedMusicFile);
    await axios
      .post(
        `https://${window.location.hostname}/api/v1/client/upload_music`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then(({ data }) => {
        console.log("Music uploaded");
        refreshMusicTop();
        setSelectedMusicFile(null);
        setUploadTrackTitle("");
      })
      .catch((error) => {
        const { status, data } = error.response;
        console.error("Response Status:", status, "Response Data:", data);
      });
  };

  const handlePlayerPauseButton = () => {
    playerRef.current.paused
      ? playerRef.current.play()
      : playerRef.current.pause();
    setCurrentlyPlaying(!playerRef.current.paused);
  };

  const [loopMode, setLoopMode] = useLocalStorage("loopMode", 0);

  const handleChangeLoopMode = () => {
    loopMode === 2 ? setLoopMode(0) : setLoopMode(loopMode + 1);
  };

  const [volume, setVolume] = useLocalStorage("volume", 0.1);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const handleVolumeChange = (event) => {
    const newVolume = parseFloat(event.target.value);
    setVolume(newVolume);
    if (playerRef.current) {
      playerRef.current.volume = newVolume;
    }
  };

  const handleTimeChange = (event) => {
    const newTime = parseFloat(event.target.value);
    console.log(`New time: ${newTime}`);

    if (playerRef.current) {
      const duration = playerRef.current.duration;

      if (newTime >= 0 && newTime <= duration) {
        playerRef.current.currentTime = newTime;
        setCurrentTime(newTime);
      } else {
        console.warn(`Invalid time: ${newTime}. Duration: ${duration}`);
      }
    } else {
      console.warn("playerRef.current is null or undefined");
    }
  };

  const handleEnd = useCallback(() => {
    console.log("Current loopMode:", loopMode);
    const [playlistId, trackId, trackIndex] = curPlayingId;
    const target_playlist = playlistsData.find((p) => p.id === playlistId);
    if (!target_playlist) return;
    const next_track = target_playlist.tracks[trackIndex + 1];
    switch (loopMode) {
      case 0:
        console.log("loop off");

        if (next_track) {
          setCurPlayingId([target_playlist.id, next_track.id, trackIndex + 1]);
          handlePlayTrack(next_track.storage_url, next_track.title);
        }
        return;
      case 1:
        console.log("loop playlist");

        if (next_track) {
          setCurPlayingId([target_playlist.id, next_track.id, trackIndex + 1]);
          handlePlayTrack(next_track.storage_url, next_track.title);
        } else {
          const new_track = target_playlist.tracks[0];
          setCurPlayingId([target_playlist.id, new_track.id, 0]);
          handlePlayTrack(new_track.storage_url, new_track.title);
        }

        return;
      case 2:
        console.log("handled loop on");
        playerRef.current.play();
        return;
    }
  }, [loopMode, playlistsData, curPlayingId]);

  const playlistHiddenInputRef = useRef(null);

  const [playlistEditorIconUrl, setPlaylistEditorIconUrl] = useState(null);
  const [playlistEditorIconBlob, setPlaylistEditorIconBlob] = useState(null);
  const [playlistEditorName, setPlaylistEditorName] = useState("");

  const handleEditIconClick = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    if (file.size > 20 * 1024 * 1024) {
      alert("Файл слишком большой. Максимальный размер - 20 МБ.");
      return;
    }
    try {
      let arrayBuffer = await file.arrayBuffer();
      let image = await Image.load(arrayBuffer);

      let originalWidth = image.width;
      let originalHeight = image.height;

      let targetSize = Math.min(originalWidth, originalHeight);

      let croppedImage = image.crop({
        x: (originalWidth - targetSize) / 2,
        y: (originalHeight - targetSize) / 2,
        width: targetSize,
        height: targetSize,
      });
      let resized_image = croppedImage.resize({ width: 256, height: 256 });
      const resizedImageBuffer = resized_image.toBuffer();
      const blob = new Blob([resizedImageBuffer], { type: "image/png" });

      setPlaylistEditorIconUrl(URL.createObjectURL(blob));
      setPlaylistEditorIconBlob(blob);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSavePlaylistClick = async () => {
    const formData = new FormData();
    formData.append("session", Cookies.get("authToken"));
    formData.append("playlist_id", curOpenedPlaylistData.id);
    formData.append("playlist_name", playlistEditorName);
    formData.append("playlist_image", playlistEditorIconBlob, "icon.png");

    await axios
      .post(
        `https://${window.location.hostname}/api/v1/data/edit_playlist`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then(({ data }) => {
        console.log("Playlist edited");
        refresh_my_playlists();
        request_playlist(curOpenedPlaylistData.id);
      })
      .catch((error) => {
        const { status, data } = error.response;
        console.error("Response Status:", status, "Response Data:", data);
      });
  };

  useEffect(() => {
    const audio = playerRef.current;

    if (audio) {
      const updateTime = () => setCurrentTime(audio.currentTime);
      const setAudioDuration = () => setDuration(audio.duration);

      audio.addEventListener("timeupdate", updateTime);
      audio.addEventListener("loadedmetadata", setAudioDuration);
      audio.addEventListener("ended", handleEnd);

      return () => {
        audio.removeEventListener("timeupdate", updateTime);
        audio.removeEventListener("loadedmetadata", setAudioDuration);
        audio.removeEventListener("ended", handleEnd);
      };
    }
  }, [handleEnd]);

  function calculateLuminance(r, g, b) {
    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
  }

  useEffect(() => {
    const img = curPlaylistImageRef.current;
    if (!img) return;
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    img.crossOrigin = "anonymous";

    img.onload = function () {
      canvas.width = img.width;
      canvas.height = img.height;

      ctx.drawImage(img, 0, 0);

      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;

      const pixels = [];
      for (let i = 0; i < data.length; i += 4) {
        pixels.push({
          r: data[i],
          g: data[i + 1],
          b: data[i + 2],
          a: data[i + 3],
        });
      }

      pixels.sort((a, b) => {
        const luminanceA = calculateLuminance(a.r, a.g, a.b);
        const luminanceB = calculateLuminance(b.r, b.g, b.b);
        return luminanceA - luminanceB;
      });

      const darkestColor = pixels[0];
      const lightestColor = pixels[pixels.length - 1];
      const middleIndex = Math.floor(pixels.length / 2);
      const averageColor = pixels[middleIndex];

      const formatColor = (color) =>
        `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a / 255})`;

      setCurPlaylistGradientStyle({
        darkest: formatColor(darkestColor),
        lightest: formatColor(lightestColor),
        average: formatColor(averageColor),
      });
    };
  }, [curPlaylistImageRef, curOpenedPlaylistData]);

  useEffect(() => {
    refreshSearch();
  }, [musicSearchField]);

  // useEffect(() => {
  //     if (curOpenedPlaylistData)
  //         setPlaylistEditorIconUrl(curOpenedPlaylistData.icon_url)
  // }, [playlistEditorModalOpened])

  useEffect(() => {
    refreshMusicTop();
    playerRef.current.volume = volume;
    refresh_my_playlists();
  }, []);

  const music_tabs = {
    main: (
      <>
        <div className="music-list-holder">
          <h2 className="Music-title">Топ 10 треков</h2>
          <div className="music-search-music-container">
            {musicTopListData &&
              musicTopListData.tracks.map((track) => (
                <div
                  className="music-item-holder"
                  key={track.id}
                  onContextMenu={(e) => {
                    var options = [
                      {
                        name: "Добавить в плейлист",
                        submenu: [],
                      },
                      {
                        name: "Лайкнуть",
                        action: () => handleLikeTrack(track.id),
                      },
                    ];
                    playlistsData &&
                      playlistsData.map((playlist) => {
                        options[0].submenu.push({
                          name: playlist.title,
                          action: () =>
                            handleAddTrackToPlaylist(track.id, playlist.id),
                        });
                      });

                    handleSongRightClick(e, options);
                  }}
                >
                  <div className="music-item-left-holder">
                    <button
                      className="music-item-play"
                      onClick={() => {
                        handlePlayTrack(track.storage_url, track.title);
                      }}
                    >
                      {(playerRef.current.src ===
                        `https://${window.location.hostname}/api${track.storage_url}`) &
                      currentlyPlaying ? (
                        <IconAdapter icon="pauseCircleIcon" color="primary" />
                      ) : (
                        <IconAdapter icon="playCircleIcon" color="primary" />
                      )}
                    </button>
                    <h3>{track.title}</h3>
                  </div>
                  <div className="music-item-right-holder">
                    <h4>{track.score}</h4>
                    <button
                      className="music-item-play"
                      onClick={() => {
                        handleLikeTrack(track.id);
                      }}
                    >
                      <IconAdapter icon="likeIcon" color="primary" />
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </div>

        <div className="music-upload-container">
          <input
            className="kitsunet-design-input-field"
            type="text"
            value={uploadTrackTitle}
            onChange={(e) => setUploadTrackTitle(e.target.value)}
          />
          <input
            ref={addMediaFileInputRef}
            type="file"
            onChange={handleMediaAdd}
            multiple
            hidden
          />
          <div className="music-upload-buttons-holder">
            <button
              className="music-upload-add-attachment"
              onClick={() => addMediaFileInputRef.current.click()}
            >
              <IconAdapter icon="attachmentIcon" color="primary" />
            </button>
            <h3>{fileSelectorText}</h3>
            <button
              className="kitsunet-design-input-button"
              onClick={uploadMusic}
            >
              Загрузить
            </button>
          </div>
        </div>
      </>
    ),
    search: (
      <>
        <div className="music-list-holder">
          <h2 className="Music-title">Результаты по твоему запросу</h2>
          <div className="music-search-music-container">
            {musicSearchData &&
              musicSearchData.tracks.map((track) => (
                <div
                  className="music-item-holder"
                  onContextMenu={(e) => {
                    var options = [
                      {
                        name: "Добавить в плейлист",
                        submenu: [],
                      },
                      {
                        name: "Лайкнуть",
                        action: () => handleLikeTrack(track.id),
                      },
                    ];
                    playlistsData &&
                      playlistsData.map((playlist) => {
                        options[0].submenu.push({
                          name: playlist.title,
                          action: () =>
                            handleAddTrackToPlaylist(track.id, playlist.id),
                        });
                      });

                    handleSongRightClick(e, options);
                  }}
                >
                  <div className="music-item-left-holder">
                    <button
                      className="music-item-play"
                      onClick={() => {
                        handlePlayTrack(track.storage_url, track.title);
                      }}
                    >
                      {(playerRef.current.src ===
                        `https://${window.location.hostname}/api${track.storage_url}`) &
                      currentlyPlaying ? (
                        <IconAdapter icon="pauseCircleIcon" color="primary" />
                      ) : (
                        <IconAdapter icon="playCircleIcon" color="primary" />
                      )}
                    </button>
                    <h3>{track.title}</h3>
                  </div>
                  <div className="music-item-right-holder">
                    <h4>{track.score}</h4>
                    <button
                      className="music-item-play"
                      onClick={() => {
                        handleLikeTrack(track.id);
                      }}
                    >
                      <IconAdapter icon="likeIcon" color="primary" />
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </>
    ),
    playlist: (
      <div
        className="music-playlist-tab"
        style={
          curPlaylistGradientStyle && {
            background: `linear-gradient(0deg,rgba(255,0,0,0) 60%, ${curPlaylistGradientStyle.average} 100%)`,
          }
        }
      >
        {curOpenedPlaylistData && (
          <>
            <div className="playlist-header">
              <img
                ref={curPlaylistImageRef}
                onClick={() => setPlaylistEditorModalOpened(true)}
                src={
                  curOpenedPlaylistData.icon_url
                    ? `/api${curOpenedPlaylistData.icon_url}`
                    : `/api/storage/playlists/track_placeholder.png`
                }
                alt=""
              />
              <div className="playlist-header-labels">
                <div style={{}}></div>
                <h5>Плейлист</h5>
                <h1 onClick={() => setPlaylistEditorModalOpened(true)}>
                  {curOpenedPlaylistData.title || ""}
                </h1>
                <h5>
                  {curOpenedPlaylistData.owner_name} •{" "}
                  {curOpenedPlaylistData.tracks_count} треков
                </h5>
              </div>
            </div>
            <div className="playlist-tracks-list">
              <div className="playlist-tracks-header">
                <div className="playlist-tracks-col playlist-tracks-col1">
                  Название
                </div>
                <div className="playlist-tracks-col playlist-tracks-col2">
                  Лайков
                </div>
                <div className="playlist-tracks-col playlist-tracks-col3">
                  Длительность
                </div>
              </div>
              <div className="playlist-tracks-seperator"></div>
              <div className="playlist-tracks-scroll-holder">
                {curOpenedPlaylistData.tracks.map((track, index) => (
                  <div
                    className="playlist-tracks-item"
                    onContextMenu={(e) => {
                      var options = [
                        {
                          name: "Удалить из плейлиста",
                          action: () =>
                            handleRemoveTrackFromPlaylist(
                              track.id,
                              index,
                              curOpenedPlaylistData.id
                            ),
                        },
                        {
                          name: "Лайкнуть",
                          action: () => handleLikeTrack(track.id),
                        },
                      ];
                      console.warn(track);
                      handleSongRightClick(e, options);
                    }}
                  >
                    <div className="playlist-tracks-col playlist-tracks-col1">
                      <div
                        className="track-icon-button-holder"
                        onClick={() => {
                          handlePlayTrack(track.storage_url, track.title);
                          setCurPlayingId([
                            curOpenedPlaylistData.id,
                            track.id,
                            index,
                          ]);
                        }}
                      >
                        <img
                          className="playlist-track-item-icon"
                          src={`https://${window.location.hostname}/api/storage/avatars/track_placeholder.png`}
                          alt=""
                        />
                        <div className="playlist-track-icon-adapter">
                          {(() => {
                            const [playlistId, trackId, trackIndex] =
                              curPlayingId;
                            const isPlaying =
                              playlistId === curOpenedPlaylistData.id &&
                              trackId === track.id &&
                              trackIndex === index;
                            return isPlaying ? (
                              <IconAdapter
                                icon="pauseCircleIcon"
                                color="primary"
                              />
                            ) : (
                              <IconAdapter
                                icon="playCircleIcon"
                                color="primary"
                              />
                            );
                          })()}
                        </div>
                      </div>
                      <div className="playlist-track-item-text">
                        <h4>{track.title}</h4>
                        <h5>Трек</h5>
                      </div>
                    </div>
                    <div className="playlist-tracks-col playlist-tracks-col2">
                      {track.score}
                    </div>
                    <div className="playlist-tracks-col playlist-tracks-col3">
                      Скоро...
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    ),
  };

  return (
    <div className="Music">
      <audio ref={playerRef} src="" hidden>
        Your browser does not support the audio element.
      </audio>
      {playlistEditorModalOpened && curOpenedPlaylistData && (
        <div className="playlist-editor-modal-holder">
          <div className="playlist-editor-modal">
            <div className="playlist-editor-header">
              <h2>Редактор плейлиста</h2>
              <div
                className="playlist-editor-close"
                onClick={() => setPlaylistEditorModalOpened(false)}
              >
                <IconAdapter icon="closeIcon" color="primary" />
              </div>
            </div>
            <div className="playlist-editor-main">
              <div className="playlist-editor-icon-editor-holder">
                <img
                  src={
                    playlistEditorIconUrl
                      ? playlistEditorIconUrl
                      : curOpenedPlaylistData.icon_url
                      ? `/api${curOpenedPlaylistData.icon_url}`
                      : `/api/storage/playlists/track_placeholder.png`
                  }
                  alt=""
                />
                <div
                  className="playlist-editor-icon-edit"
                  onClick={() => playlistHiddenInputRef.current.click()}
                >
                  <IconAdapter icon="attachmentIcon" color="primary" />
                </div>
                <input
                  ref={playlistHiddenInputRef}
                  type="file"
                  accept=".jpeg,.jpg,.png"
                  onChange={handleEditIconClick}
                  style={{ display: "none" }}
                />
              </div>
              <div className="playlist-editor-inputs-holder">
                <input
                  className="kitsunet-design-input-field"
                  type="text"
                  placeholder="Введите название"
                  value={playlistEditorName}
                  onChange={(e) => setPlaylistEditorName(e.target.value)}
                />
              </div>
            </div>
            <button
              className="kitsunet-design-input-button"
              onClick={handleSavePlaylistClick}
            >
              Сохранить
            </button>
            <div className="playlist-editor-footer">
              <h4>
                Не загужайте изображения содержащие неприемлемое содержимое
              </h4>
            </div>
          </div>
        </div>
      )}

      <div className="music-search-panel">
        <input
          className="kitsunet-design-input-field"
          type="text"
          placeholder="Что бы вы хотели включить?"
          required
          value={musicSearchField}
          onChange={(e) => setMusicSearchField(e.target.value)}
        />
      </div>

      <div className="music-main-panel">
        <div className="music-main-panel-middle-panel">
          {music_tabs[curPage] || ""}
        </div>

        <div className="music-main-panel-right-panel">
          <div className="music-right-panel-buttons-holder">
            <button
              className="music-panel-curcled-buttons"
              onClick={() => setCurPage("main")}
            >
              <IconAdapter icon="homeIcon" color="primary" />
            </button>
            <button
              className="music-panel-curcled-buttons"
              onClick={() => create_play_list()}
            >
              +
            </button>
          </div>
          <div className="playlist-scroll-holder">
            {playlistsData &&
              playlistsData.map((playlist) => (
                <div
                  className="playlist-scroll-item"
                  key={playlist.id}
                  onClick={() => request_playlist(playlist.id)}
                  onContextMenu={(e) => {
                    var options = [
                      {
                        name: "Удалить",
                        submenu: [
                          {
                            name: "Точно?",
                            action: () => handleDeletePlaylist(playlist.id),
                          },
                        ],
                      },
                      {
                        name: "Добавить друга",
                        submenu: [
                          {
                            name: "скоро",
                            action: () => {
                              closeContextMenu();
                            },
                          },
                        ],
                      },
                    ];
                    handleSongRightClick(e, options);
                  }}
                >
                  <div className="playlist-item-holder">
                    <img
                      src={
                        playlist.icon_url
                          ? `/api${playlist.icon_url}`
                          : `/api/storage/playlists/track_placeholder.png`
                      }
                      alt=""
                    />
                    <div className="labels-holder">
                      <h3>{playlist.title}</h3>
                      <h4>
                        {playlist.owner_name} • {playlist.tracks_count} треков
                      </h4>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

      <div className="music-player-holder">
        <button
          className="music-player-play-button"
          onClick={handlePlayerPauseButton}
          disabled={!curTrack}
        >
          {currentlyPlaying ? (
            <IconAdapter icon="pauseCircleIcon" color="primary" />
          ) : (
            <IconAdapter icon="playCircleIcon" color="primary" />
          )}
        </button>
        <div className="music-player-range-title-holder">
          <h4>{curTrack ? curTrack : "Выберите музыку"}</h4>
          <div className="slidecontainer">
            <div className="duration-slider-holder">
              {formatTime(currentTime)}
              <input
                type="range"
                min="0"
                max={duration || 0}
                className="slider"
                step="0.1"
                value={currentTime}
                onChange={handleTimeChange}
                style={
                  duration
                    ? {
                        background: `linear-gradient(to right, var(--theme-primary) ${
                          (currentTime / duration) * 100
                        }%, var(--theme-background) ${
                          (currentTime / duration) * 100
                        }%)`,
                      }
                    : {
                        background: `linear-gradient(to right, var(--theme-primary) ${0}%, var(--theme-background) ${0}%)`,
                      }
                }
              />
              {formatTime(duration)}
              <button
                className="music-player-repeat-mode"
                onClick={handleChangeLoopMode}
              >
                <IconAdapter
                  icon={loopMode === 2 ? "repeatOneIcon" : "repeatIcon"}
                  color={loopMode === 0 ? "background" : "primary"}
                />
              </button>
            </div>

            <div className="volume-slider-holder">
              <h4>Громкость: </h4>
              <input
                className="slider"
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={volume}
                onChange={handleVolumeChange}
                style={{
                  background: `linear-gradient(to right, var(--theme-primary) ${
                    volume * 100
                  }%, var(--theme-background) ${volume * 100}%)`,
                }}
              />
              {Math.round(volume * 100)}%
            </div>
          </div>
        </div>
      </div>
      {contextMenu.visible && (
        <ContextMenu
          options={contextMenu.options}
          position={contextMenu.position}
          onClose={closeContextMenu}
        />
      )}
    </div>
  );
};

export default Music;
