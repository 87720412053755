import React from "react";
import { useState, useEffect } from "react";

import "./Home.css";

import axios from "axios";
import Cookies from "js-cookie";
import { API_SERVER } from "../config.js";


const Home = ( { refresh_user_main_info } ) => {
  const [timeLeft, setTimeLeft] = useState("Можно забрать через...");
  const [dailyLock, setDailyLock] = useState(true);
  const [tasksDailyTicksCooldown, setTasksDailyTicksCooldown] = useState(
    new Date()
  );

  function formatDateTime(date) {
    const givenDate = new Date(date);
    const currentDate = new Date();
    const diffTime = Math.abs(currentDate.getTime() - givenDate.getTime());

    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const diffYears = Math.floor(diffDays / 365);

    let formattedTime = givenDate.toLocaleTimeString("ru-RU", {
      hour: "2-digit",
      minute: "2-digit",
    });

    let formattedDate = "";
    if (givenDate.getDate() !== currentDate.getDate()) {
      formattedDate = givenDate.toLocaleDateString("ru-RU", {
        day: "numeric",
        month: "short",
      });
    }

    let formattedYear = "";
    if (diffYears >= 1) {
      formattedYear = ` ${givenDate.getFullYear()}`;
    }

    return `${formattedDate} ${formattedTime} ${formattedYear}`;
  }

  const refresh_dailies = async () => {
    const formData = new FormData();
    formData.append("session", Cookies.get("authToken"));

    try {
      const response = await axios.post(
        `https://${window.location.hostname}/api/v1/data/get_my_daily`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response) {
        console.log("Dailies info update");
        if (response.data.length === 0) {
          setTasksDailyTicksCooldown("2018-06-28T01:05:46.962243");
        } else {
          const date_localTime = new Date(response.data[0].last_collect);
          const mill = date_localTime.getTime();
          setTasksDailyTicksCooldown(mill);
        }
      }
    } catch (error) {
      if (error.response) {
        console.error(
          "Response Status:",
          error.response.status,
          "Response Data:",
          error.response.data
        );
      }
    }
  };

  const [newsData, setNewsData] = useState(null)

  const refreshNews = async () => {
    const formData = new FormData();
    formData.append("session", Cookies.get("authToken"));
    await axios.post(
      `https://${window.location.hostname}/api/v1/data/get_news`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data", },
      }
    ).then(({ data }) => {
      console.log("News update");
      setNewsData(data);
    }).catch(error => {
      const { status, data } = error.response;
      console.error("Response Status:", status, "Response Data:", data);
    })
  };

  const handleCollectDailyTicks = async () => {
    const formData = new FormData();
    formData.append("session", Cookies.get("authToken"));

    try {
      const response = await axios.post(
        `https://${window.location.hostname}/api/v1/client/collect_daily_ticks`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response) {
        console.log("Success collected ticks");
        setDailyLock(true);
        refresh_dailies();
        refresh_user_main_info();
      }
    } catch (error) {
      if (error.response) {
        console.error(
          "Response Status:",
          error.response.status,
          "Response Data:",
          error.response.data
        );
      }
    }
  };

  const calculateDailyTimer = (last_collect) => {
    const now = new Date(Date.now());
    const now_mill = now.getTime();

    const delta_time = now_mill - last_collect;

    const time_left = 86400000 - delta_time;

    if (time_left <= 0) {
      setDailyLock(false);
      return "Уже можно забирать!";
    }

    const hours = Math.floor(time_left / 3600000);
    const minutes = Math.floor((time_left % 3600000) / 60000);
    const seconds = Math.floor((time_left % 60000) / 1000);

    return `Можно забрать через ${hours} часов ${minutes} минут ${seconds} секунд`;
  };

  useEffect(() => {
    const timer = setInterval(() => {
      const newTimeLeft = calculateDailyTimer(tasksDailyTicksCooldown);
      setTimeLeft(newTimeLeft);
    }, 1000); // Обновляем каждую секунду

    // Очищаем таймер при размонтировании компонента
    return () => clearInterval(timer);
  }, [tasksDailyTicksCooldown]);

  useEffect(() => {
    refresh_dailies();
    refreshNews();
  }, []);

  return (
    <div className="Home">
      <div className="main-tab-container">
        <h1>Главное</h1>
        <div className="daily-ticks-container">
          <div className="daily-ticks-container-text">
            <h2>Тики за ежедневный вход</h2>
            <h3>{timeLeft}</h3>
          </div>

          <button
            className="kitsunet-design-input-button"
            onClick={handleCollectDailyTicks}
            disabled={dailyLock}
          >
            Забрать
          </button>
        </div>
        <div className="news-container">
            <h2>Последние новости</h2>
            {newsData && newsData.posts.map((news_item) => (
              <div className="news-item-contaner" key={news_item.title+"-"+news_item.created_at}>
              <h3>{news_item.title}</h3>
              <h4>{news_item.text}</h4>
              <h5>{formatDateTime(news_item.created_at)}</h5>
            </div>
            ))}
            
            
        </div>
        <h2>
          Скоро здесь появиться много нового, за вопросами -{" "}
          <a href="https://t.me/SprintFox">Telegram</a>
        </h2>
        <h2>
          Поддержать копейкой -{" "}
          <a href="https://www.tinkoff.ru/cf/1W2nWFXshSR">Копилка</a>
        </h2>
        <h5 style={{ color: "var(--theme-secondary)" }}>Kitsunet - v1.2.5</h5>
      </div>
    </div>
  );
};

export default Home;
