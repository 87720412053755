import "./Main.css";
import { API_SERVER } from "./config.js";

import IconAdapter from "./icons/IconAdapter.jsx";

import axios from "axios";
import Cookies from "js-cookie";
import { Image } from "image-js";
import React, { useState, useEffect, useRef } from "react";

import NotificationComponent from "./components/NotificationComponent.js";
import MediaViewer from "./components/MediaViewer.js";
import ConfirmationDialog from "./components/ConfirmationDialog.js";

import Home from "./screens/Home.jsx";

import Friends from "./screens/Friends.jsx";
import Search from "./screens/Search.jsx";


import Music from "./screens/Music.jsx";


import Game from "./screens/Game.jsx";

import Settings from "./screens/Settings.jsx";

import Test from "./screens/Test.jsx";
import Guilds from "./screens/Guilds.jsx";
import Entertainings from "./screens/entertainings/Entertainings.jsx";

const Tools = React.lazy(() => import('./screens/Tools.jsx'));

const MyProfile = React.lazy(() => import('./screens/MyProfile.jsx'));
const UserProfile = React.lazy(() => import('./screens/UserProfile.jsx'));

const Messenger = React.lazy(() => import('./screens/Messenger.jsx'));

const Main = () => {
  const requestOtherUserPageRef = useRef();
  const openChatWithUserRef = useRef();

  const [myUserData, setMyUserData] = useState([]);

  const [friendsRequestsData, setFriendsRequestsData] = useState([]);
  const [friendsData, setFriendsData] = useState([]);

  const [requestedUserId, setRequestedUserId] = useState(null);

  const [navigationBarToggle, setNavigationBarToggle] = useState(true);

  const openChatWithUser = (user_id) => {
    switchTab("messenger");
    if (openChatWithUserRef.current) {
      openChatWithUserRef.current.openChatWithUser(user_id);
    }
  };

  const requestOtherUserPage = (user_id) => {
    setRequestedUserId(user_id);
    localStorage.setItem("lastRequestedUserID", user_id);

  };

  const [activeTab, setActiveTab] = useState(() => {
    const tab = localStorage.getItem("activeTab") || "main";
    if (tab === "other-user") {
      requestOtherUserPage(localStorage.getItem("lastRequestedUserID"));
    }
    return tab;
  });

  const switchTab = (tabId) => {
    localStorage.setItem("activeTab", tabId);
    setActiveTab(tabId);
  };

  useEffect(() => {
    if (requestedUserId && requestOtherUserPageRef.current) {
      requestOtherUserPageRef.current.request_user_page(requestedUserId);
      switchTab("other-user");
      setRequestedUserId(null);
    }
  }, [requestedUserId]);

  ///TODO Переписать репорт активности на серверную часть

  const reportUserActivity = async () => {
    try {
      var session_token = Cookies.get("authToken");
      await axios.post(`https://${window.location.hostname}/api/v1/client/online_ping`, {
        session_token,
      });
      console.log("Online ping");
    } catch (error) {
      if (error.response) {
        showNotification(`Error: ${error.response.data.detail}`);
        console.error(
          "Response Status:",
          error.response.status,
          "Response Data:",
          error.response.data
        );
      }
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      reportUserActivity();
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  const notificationRef = useRef();
  const mediaViewerRef = useRef();
  const confirmationDialogRef = useRef();

  function showNotification(title, text) {
    if (notificationRef.current) {
      notificationRef.current.showNotification(title, text);
    }
  }

  function openMedia(media_url) {
    if (mediaViewerRef.current) {
      mediaViewerRef.current.openMedia(`${media_url}`);
    }
  }

  function openDialog(text, target_func) {
    if (confirmationDialogRef.current) {
      confirmationDialogRef.current.showDialog(text, target_func);
    }
  }

  const refresh_all_user_data = async () => {
    refresh_user_main_info();
    refresh_friends();
  };

  const refresh_user_main_info = async () => {
    try {
      var session_token = Cookies.get("authToken");
      const response = await axios.post(
        `https://${window.location.hostname}/api/v1/data/get_my_user`,
        { session_token }
      );
      if (response) {
        console.log("My data update");
        setMyUserData(response.data);
      }
    } catch (error) {
      if (error.response) {
        showNotification(`Error: ${error.response.data.detail}`);
        console.error(
          "Response Status:",
          error.response.status,
          "Response Data:",
          error.response.data
        );
      }
    }
  };

  const refresh_friends = async () => {
    const session_token = Cookies.get("authToken");
    try {
      const response = await axios.post(
        `https://${window.location.hostname}/api/v1/data/get_my_friend_requests`,
        { session_token }
      );
      if (response) {
        console.log(`Friend request data update`);
        setFriendsRequestsData(response.data);
      }
    } catch (error) {
      if (error.response) {
        showNotification(`Error: ${error.response.data.detail}`);
        console.error(
          "Response Status:",
          error.response.status,
          "Response Data:",
          error.response.data
        );
      }
    }

    try {
      const response = await axios.post(
        `https://${window.location.hostname}/api/v1/data/get_my_friends`,
        { session_token }
      );
      if (response) {
        console.log(`Friends data update`);
        setFriendsData(response.data);
      }
    } catch (error) {
      if (error.response) {
        showNotification(`Error: ${error.response.data.detail}`);
        console.error(
          "Response Status:",
          error.response.status,
          "Response Data:",
          error.response.data
        );
      }
    }
  };

  const handleProfileAvatarClick = async (event) => {
    const file = event.target.files[0];

    if (!file) {
      return;
    }

    if (file.size > 20 * 1024 * 1024) {
      alert("Файл слишком большой. Максимальный размер - 20 МБ.");
      return;
    }
    try {
      let arrayBuffer = await file.arrayBuffer();
      let image = await Image.load(arrayBuffer);

      let originalWidth = image.width;
      let originalHeight = image.height;

      let targetSize = Math.min(originalWidth, originalHeight);

      let croppedImage = image.crop({
        x: (originalWidth - targetSize) / 2,
        y: (originalHeight - targetSize) / 2,
        width: targetSize,
        height: targetSize,
      });
      let resized_image = croppedImage.resize({ width: 256, height: 256 });
      const resizedImageBuffer = resized_image.toBuffer();
      const blob = new Blob([resizedImageBuffer], { type: "image/png" });

      const formData = new FormData();
      formData.append("session", Cookies.get("authToken"));
      formData.append("file", blob, "avatar.png");

      axios
        .post(`https://${window.location.hostname}/api/v1/client/upload_avatar`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log("Success avatar upload");
          refresh_user_main_info();
        })
        .catch((error) => {
          console.error("Error:", error.message);
          if (error.response) {
            console.error("Response Status:", error.response.status);
            console.error("Response Data:", error.response.data);
          }
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const navigationBarRef = useRef(null);

  useEffect(() => {
    if (navigationBarRef.current) {
      if (!navigationBarToggle){
        navigationBarRef.current.style.transform = 'translateX(-100%)';
        navigationBarRef.current.style.width = '0vw';
      } else {
        navigationBarRef.current.style.transform = 'translateX(0)';
        navigationBarRef.current.style.width = '14vw';
      }
    }
  }, [navigationBarToggle, navigationBarRef])

  useEffect(() => {
    refresh_all_user_data();
    // document.querySelector(".phonk-video").volume = 0.05;
  }, []);

  return (
    <div className="Main">
      <NotificationComponent ref={notificationRef} />
      <MediaViewer ref={mediaViewerRef} />
      <ConfirmationDialog ref={confirmationDialogRef} />
      <div className="nav-menu-toggler" onClick={() => setNavigationBarToggle(!navigationBarToggle)}>
        {navigationBarToggle ? 
        <IconAdapter icon='arrowBackIcon' color="primary"/>
        : 
        <IconAdapter icon='arrowForwardIcon' color="primary"/>}
        
      </div>
      <div className="navigation-container">
        <div className="navigation-bar" ref={navigationBarRef}>
          <div
            className="nav-kitsunet-button"
            onClick={() => switchTab("main")}
          >
            <span>Kitsunet</span>
            <IconAdapter icon='tailIcon' color="primary"/>
          </div>
          <div className="nav-buttons-container">
            <div
              className={`nav-button-item ${activeTab === "my-profile" ? "nav-light-button" : ""
                }`}
              onClick={() => switchTab("my-profile")}
            >
              {/* <img src={PersonIcon} alt="my profile" /> */}
              <IconAdapter icon='profileIcon' color={activeTab === "my-profile" ? "accentBackground" : "primary"}/>
              <h6>Мой профиль</h6>
            </div>
            <div
              className={`nav-button-item ${activeTab === "friends" ? "nav-light-button" : ""
                }`}
              onClick={() => switchTab("friends")}
            >
              <IconAdapter icon='friendsIcon' color={activeTab === "friends" ? "accentBackground" : "primary"}/>
              <h6>Друзья</h6>
            </div>
            <div
              className={`nav-button-item ${activeTab === "search" ? "nav-light-button" : ""
                }`}
              onClick={() => switchTab("search")}
            >
              <IconAdapter icon='searchIcon' color={activeTab === "search" ? "accentBackground" : "primary"}/>
              <h6>Поиск</h6>
            </div>
            <div
              className={`nav-button-item ${activeTab === "settings" ? "nav-light-button" : ""
                }`}
              onClick={() => switchTab("settings")}
            >
              <IconAdapter icon='settingsIcon' color={activeTab === "settings" ? "accentBackground" : "primary"}/>
              <h6>Настройки</h6>
            </div>
            <div
              className={`nav-button-item nav-button-long-item ${activeTab === "messenger" ? "nav-light-button" : ""
                }`}
              onClick={() => switchTab("messenger")}
            >
              <IconAdapter icon='messageIcon' color={activeTab === "messenger" ? "accentBackground" : "primary"}/>
              <h6>Мессенджер</h6>
            </div>
            <div
              className={`nav-button-item ${activeTab === "music" ? "nav-light-button" : ""
                }`}
              onClick={() => switchTab("music")}
            >
              <IconAdapter icon='musicIcon' color={activeTab === "music" ? "accentBackground" : "primary"}/>
              <h6>Музыка</h6>
            </div>
            <div
              className={`nav-button-item ${activeTab === "tools" ? "nav-light-button" : ""
                }`}
              onClick={() => switchTab("tools")}
            >
              <IconAdapter icon='toolsIcon' color={activeTab === "tools" ? "accentBackground" : "primary"}/>
              <h6>Инструменты</h6>
            </div>
            <div
              className={`nav-button-item nav-button-long-item ${activeTab === "guilds" ? "nav-light-button" : ""
                }`}
              onClick={() => switchTab("guilds")}
            >
              <IconAdapter icon='messageIcon' color={activeTab === "guilds" ? "accentBackground" : "primary"}/>
              <h6>Гильдии (ранний доступ)</h6>
            </div>
            <div
              className={`nav-button-item ${activeTab === "entertainings" ? "nav-light-button" : ""
                }`}
              onClick={() => switchTab("entertainings")}
            >
              <IconAdapter icon='joystickIcon' color={activeTab === "entertainings" ? "accentBackground" : "primary"}/>
              <h6>Развлечения</h6>
            </div>
            <div
              className={`nav-button-item ${activeTab === "test" ? "nav-light-button" : ""
                }`}
              onClick={() => switchTab("test")}
            >
              <IconAdapter icon='codeIcon' color={activeTab === "test" ? "accentBackground" : "primary"}/>
              <h6>Тесты</h6>
            </div>
          </div>
        </div>

        <div
          id="main"
          className="tab-content"
          style={
            activeTab === "main"
              ? { display: "block" }
              : { display: "none" }
          }
        >
          <Home/>
        </div>
        <div
          id="my-profile"
          className="tab-content"
          style={
            activeTab === "my-profile"
              ? { display: "block" }
              : { display: "none" }
          }
        >
          <MyProfile
            my_user_data={myUserData}
            friends_data={friendsData}
            handleProfileAvatarClick={handleProfileAvatarClick}
            switchTab={switchTab}
            request_user_page={requestOtherUserPage}
            openMedia={openMedia}
          ></MyProfile>
        </div>

        <div
          id="test"
          className="tab-content"
          style={
            activeTab === "test" ? { display: "block" } : { display: "none" }
          }
        >
          <Test></Test>
        </div>

        <div
          id="music"
          className="tab-content"
          style={
            activeTab === "music" ? { display: "block" } : { display: "none" }
          }
        >
          <Music></Music>
        </div>

        <div
          id="chat"
          className="tab-content"
          style={
            activeTab === "messenger"
              ? { display: "block" }
              : { display: "none" }
          }
        >
          <Messenger
            ref={openChatWithUserRef}
            my_user_id={myUserData.id}
            openConfirmationDialog={openDialog}
            request_user_page={requestOtherUserPage}
            openMedia={openMedia}
          ></Messenger>
        </div>

        <div
          id="guilds"
          className="tab-content"
          style={
            activeTab === "guilds"
              ? { display: "block" }
              : { display: "none" }
          }
        >
          <Guilds my_user_id={myUserData.id}></Guilds>
        </div>

        <div
          className="tab-content"
          style={
            activeTab === "tools"
              ? { display: "block" }
              : { display: "none" }
          }
        >
          <Tools/>
        </div>

        <div
          id="search"
          className="tab-content"
          style={
            activeTab === "search" ? { display: "block" } : { display: "none" }
          }
        >
          <Search request_page={requestOtherUserPage} switch_tab={switchTab} />
        </div>

        <div
          id="entertainings"
          className="tab-content"
          style={
            activeTab === "entertainings" ? { display: "block" } : { display: "none" }
          }
        >
          <Entertainings myUserData={myUserData} refresh_user_main_info={refresh_user_main_info}/>
        </div>

        <div
          id="other-user"
          className="tab-content"
          style={
            activeTab === "other-user"
              ? { display: "block" }
              : { display: "none" }
          }
        >
          <UserProfile
            ref={requestOtherUserPageRef}
            openMedia={openMedia}
            friendsRequestsData={friendsRequestsData}
            friendsData={friendsData}
            refresh_friends={refresh_friends}
            openChatWithUser={openChatWithUser}
          />
        </div>
        <div
          id="friends"
          className="tab-content"
          style={
            activeTab === "friends" ? { display: "block" } : { display: "none" }
          }
        >
          <Friends
            refresh_friends={refresh_friends}
            friendsData={friendsData}
            friendsRequestsData={friendsRequestsData}
            switch_tab={switchTab}
            request_page={requestOtherUserPage}
          />
        </div>

        <div
          id="settings"
          className="tab-content"
          style={
            activeTab === "settings"
              ? { display: "block" }
              : { display: "none" }
          }
        >
          {myUserData.length !== 0 && (
            <Settings
              my_user_data={myUserData}
              refresh_user_data={refresh_user_main_info}
              add_avatar={handleProfileAvatarClick}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Main;
