import React from "react";
import { useState, useEffect, useRef } from "react";
import "./Entertainings.css";
import IconAdapter from "../../icons/IconAdapter";

import Casino from "./apps/Casino"
import Game from "../Game";
import Phonk from "./apps/phonk/Phonk";

const Entertainings = ( {myUserData, refresh_user_main_info} ) => {
    const appsData = [{
        name: "Kitsunet слоты",
        description: "Азартная игра без реальных денег",
        id: "slots",
        icon_url: "/slots.png"
    },{
        name: "Level 74",
        description: "Тест Unity WebGL импорта на сайте",
        id: "level74",
        icon_url: "/level.png"
    },{
        name: "Фонк",
        description: "Послушайте спокойный фонк",
        id: "phonk",
        icon_url: "/phonk.png"
    },]
    const [appPlayerHolderOpened, setAppPlayerOpened] = useState(false);
    const appPlayerRef = useRef(null);

    const [curOpenedApp, setCurOpenedApp] = useState(null);

    const requestApp = (appId) => {
        setCurOpenedApp(appId);
        if (!appPlayerHolderOpened)
            setAppPlayerOpened(true)
    }

    useEffect(() => {
        if (!appPlayerRef.current)
            return;

        if (!appPlayerHolderOpened){
            appPlayerRef.current.style.height = '0vw';
          } else {
            appPlayerRef.current.style.height = '100vh';
          }

    }, [appPlayerHolderOpened, appPlayerRef])

    const appsArray = {
        "slots": <Casino balance={myUserData.ticks} refresh={refresh_user_main_info}/>,
        "level74": <Game/>,
        "phonk": <Phonk/>
    }

    return (
        <div className="Entertainings">
            <div className="entertain-player-holder" ref={appPlayerRef}>
                {appsArray[curOpenedApp]}
            </div>
            <div className="entertain-header">
                <div className="entertain-header-media">
                    <IconAdapter icon='joystickIcon' color='primary' />
                </div>
                <div className="entertain-header-text">
                    <main-title>Центр развлечений</main-title>
                    <main-description>Здесь ты сможешь найти как забавные так и достаточно интересные вещи. Мы будем пополнять этот раздел время от времени!</main-description>
                </div>
            </div>
            <div className="entertain-items-scroller">
                {appsData.map((app) => (
                    <div className="entertain-item" key={app.id}>
                        <div className="ent-item-media">
                            <img src={app.icon_url} alt="" />
                            <div className="ent-item-media-transparent"/>
                        </div>
                        <item-title>{app.name}</item-title>
                        <item-description>{app.description}</item-description>
                        <div className="kitsunet-design-input-button" onClick={() => requestApp(app.id)}>
                            Запустить
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Entertainings;
