import React, { forwardRef, useState } from 'react';
import './OptionsMenu.css';

const OptionsMenu = ({ options, position, onClose }) => {
  const [submenu, setSubmenu] = useState(null);

  if (!options || options.length === 0) return null;

  return (
    <div
      style={{
        position: 'absolute',
        top: position.y,
        left: position.x,
        background: 'var(--theme-background)',
        border: '1px solid var(--theme-primary)',
        borderRadius: '1vw',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        zIndex: 1000,
        padding: '5px',
        width: '12vw',
        boxSizing: 'border-box'
      }}
      onMouseLeave={onClose}
    >
      {options.map((option, index) => (
        <div
          key={index}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '8px 16px',
            cursor: 'pointer',
            height: '2vh',
            borderBottom: index !== options.length - 1 ? '1px solid var(--theme-primary)' : 'none',
          }}
          onMouseEnter={() => {
            if (option.submenu) {
              setSubmenu({
                options: option.submenu,
                position: { x: '11vw', y: `${index * 4}vh` },
              });
            } else {
              setSubmenu(null);
            }
          }}
          onClick={() => {
            if (option.action) option.action();
            onClose();
          }}
        >
          {option.name}
          {option.submenu && (
            <span style={{ float: 'right', fontSize: '12px' }}>▶</span>
          )}
        </div>
      ))}
      {submenu && (
        <OptionsMenu
          options={submenu.options}
          position={submenu.position}
          onClose={() => setSubmenu(null)}
        />
      )}
    </div>
  );
};

export default OptionsMenu;