import React, { useContext } from "react";
import ThemeContext from "../context/ThemeContext";

const IconAdapter = ({ icon, color }) => {
  const { theme } = useContext(ThemeContext);

  let targetColor;

  switch (color) {
    case "primary":
      targetColor = theme.primary;
      break;
    case "secondary":
      targetColor = theme.secondary;
      break;
    case "background":
      targetColor = theme.background;
      break;
    case "accentBackground":
      targetColor = theme.accentBackground;
      break;
    default:
      targetColor = theme.primary; // Значение по умолчанию
  }

  switch (icon) {
    case "profileIcon":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill={targetColor}
        >
          <path d="M364-404q-22 0-37-15t-15-37q0-22 15-37t37-15q22 0 37 15t15 37q0 22-15 37t-37 15Zm232 0q-22 0-37-15t-15-37q0-22 15-37t37-15q22 0 37 15t15 37q0 22-15 37t-37 15ZM480.93-178q124.99 0 213.53-87.78Q783-353.57 783-477.51q0-23.24-5-51.99-5-28.75-13-49.5-21 5-36.85 6.5-15.86 1.5-38.35 1.5-83.76 0-157.31-32t-133.87-94Q364-622 309.4-572.81T181-497.68q-1 3.99-1.5 9.99t-.5 9.99q0 123.84 88.48 211.77Q355.95-178 480.93-178ZM481-50q-87.9 0-166.61-33.52-78.71-33.52-137.53-92.34-58.82-58.82-92.34-137.7T51-480.54q0-88.1 33.52-166.94t92.34-137.16q58.82-58.32 137.7-91.84T481.54-910q88.1 0 166.45 33.76 78.34 33.76 136.45 91.92 58.1 58.16 91.83 137.16Q910-568.15 910-479.92q0 87.94-33.52 166.59t-91.84 137.47q-58.32 58.82-137.03 92.34Q568.9-50 481-50Z" />
        </svg>
      );
    case "friendsIcon":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill={targetColor}
        >
          <path d="M42-2Q15-2-3.5-20.5T-22-66v-8q0-38 26-64.5T77-178q6-1 19-4.5t22-5.5q14-1 23.5 12t6.5 29q-2 10-4 23t-2 29v29q0 27-18.5 45.5T78-2H42Zm240 0q-27 0-45.5-18.5T218-66v-29q0-76 71.5-121.5T480-262q119 0 190.5 45.5T742-95v29q0 27-18.5 45.5T678-2H282Zm600 0q-27 0-45.5-18.5T818-66v-29q0-16-2-29t-4-23q-3-16 6.5-29t23.5-12q9 2 22 5.5t19 4.5q47 13 73 39.5T982-74v8q0 27-18.5 45.5T918-2h-36ZM168-233q-35 0-60.5-25T82-318q0-36 25.5-61t60.5-25q35 0 60.5 25t25.5 61q0 35-25.5 60T168-233Zm624 0q-35 0-60.5-25T706-318q0-36 25.5-61t60.5-25q35 0 60.5 25t25.5 61q0 35-25.5 60T792-233Zm-312-52q-59 0-100.5-41.5T338-427q0-59 41.5-100.5T480-569q59 0 100.5 41.5T622-427q0 59-41.5 100.5T480-285Zm0-485q46-46 101.5-70T696-864q110 0 187 77t77 187q0 27-6.5 53T936-497q-6 26-30.5 39t-50.5 6q-26-8-38.5-31t-5.5-47q11-16 16-33.5t5-36.5q0-56-40-96t-96-40q-45 0-88.5 18T531-669q-21 20-51 20t-51-20q-34-31-77-49t-88-18q-56 0-96 40t-40 96q0 19 6 36.5t15 33.5q7 25-6 46.5T104-454q-25 7-49.5-5T23-496q-12-24-17.5-50.5T0-600q0-110 77-187t187-77q59 0 114.5 24T480-770Z" />
        </svg>
      );
    case "searchIcon":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill={targetColor}
        >
          <path d="M375.03-305q-112.74 0-192.39-79.7Q103-464.41 103-577.2q0-112.8 79.7-192.3Q262.41-849 375.2-849q112.8 0 192.3 79.64Q647-689.71 647-576.97q0 43.39-10 78.18Q627-464 609-435l204 205q19 20 19 44.48 0 24.48-20 44.52-19.8 19-44.9 19-25.1 0-45.1-19L520.47-343q-29.47 17-66.26 27.5-36.79 10.5-79.18 10.5Zm-.44-128q61.59 0 103-41T519-576.59q0-61.59-41.41-103t-103-41.41Q313-721 272-679.59t-41 103Q231-515 272-474t102.59 41Z" />
        </svg>
      );
    case "settingsIcon":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill={targetColor}
        >
          <path d="M412-50q-22 0-37.5-12T356-95l-16-82q-13-6-26.5-13T289-207l-78 27q-19 6-39.5 0T142-205L74-327q-10-18-7-38t19-32l60-54q-1-7-1.5-14.5T144-481q0-8 .5-15t1.5-14l-59-52q-17-13-20-33t7-39l68-120q10-18 29.5-24.5t38.5-.5l79 26q12-9 25-16t27-13l15-82q2-22 18-34.5t38-12.5h136q22 0 38 12.5t18 34.5l15 82q14 6 28 13t26 17l75-27q20-6 40 .5t30 24.5l68 120q10 19 7 39t-20 33l-61 52q1 7 2 14.5t1 15.5q0 8-1.5 15.5T812-450l61 52q17 12 20 32t-7 39l-68 122q-9 18-29 24.5t-40 .5l-80-27q-11 9-23.5 16.5T620-178l-16 83q-3 21-18.5 33T548-50H412Zm64-276q65 0 110-45t45-110q0-64-45-109.5T476-636q-64 0-109.5 45.5T321-481q0 65 45.5 110T476-326Z" />
        </svg>
      );
    case "messageIcon":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill={targetColor}
        >
          <path d="m204-200-44.88 44.88Q129-125 89.5-140.69 50-156.38 50-200v-587q0-53.83 37.09-90.91Q124.18-915 178-915h604q53.83 0 90.91 37.09Q910-840.83 910-787v459q0 53.83-37.09 90.91Q835.83-200 782-200H204Zm66-184h275q15.3 0 25.65-10.29Q581-404.58 581-419.79t-10.35-25.71Q560.3-456 545-456H270q-15.3 0-25.65 10.29Q234-435.42 234-420.21t10.35 25.71Q254.7-384 270-384Zm0-138h420q15.3 0 25.65-10.29Q726-542.58 726-557.79 726-572 715.65-583T690-594H270q-15.3 0-25.65 10.79Q234-572.42 234-557.71t10.35 25.21Q254.7-522 270-522Zm0-138h420q15.3 0 25.65-10.29Q726-680.58 726-695.79 726-710 715.65-721T690-732H270q-15.3 0-25.65 10.79Q234-710.42 234-695.71t10.35 25.21Q254.7-660 270-660Z" />
        </svg>
      );
    case "musicIcon":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill={targetColor}
        >
          <path d="M388-68q-80 0-137-57t-57-136.5q0-79.5 57-137T388-456q16 0 29.5 2t28.5 5v-375q0-27.6 19.5-47.8Q485-892 514-892h185q27.6 0 47.8 20.2Q767-851.6 767-824v85q0 27.6-20.2 47.8Q726.6-671 699-671H582v409q0 80-57.51 137Q466.99-68 388-68Z" />
        </svg>
      );
    case "diceIcon":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill={targetColor}
        >
          <path d="M333.29-267q27.06 0 45.89-19.62Q398-306.24 398-333.29q0-27.06-18.94-45.89-18.94-18.82-46-18.82t-46.56 18.94q-19.5 18.94-19.5 46t19.62 46.56q19.62 19.5 46.67 19.5Zm0-295q27.06 0 45.89-18.94 18.82-18.94 18.82-46t-18.94-46.56q-18.94-19.5-46-19.5t-46.56 19.62Q267-653.76 267-626.71q0 27.06 19.62 45.89Q306.24-562 333.29-562Zm146.65 147Q507-415 526-433.94q19-18.94 19-46T526.06-526q-18.94-19-46-19T434-526.06q-19 18.94-19 46T433.94-434q18.94 19 46 19Zm147.35 148q27.06 0 45.89-19.62Q692-306.24 692-333.29q0-27.06-18.94-45.89-18.94-18.82-46-18.82t-46.56 18.94q-19.5 18.94-19.5 46t19.62 46.56q19.62 19.5 46.67 19.5Zm-.35-295q27.06 0 46.56-18.94 19.5-18.94 19.5-46t-19.62-46.56Q653.76-693 626.71-693q-27.06 0-45.89 19.62Q562-653.76 562-626.71q0 27.06 18.94 45.89 18.94 18.82 46 18.82ZM228-100q-53.82 0-90.91-37.09Q100-174.18 100-228v-504q0-53.83 37.09-90.91Q174.18-860 228-860h504q53.83 0 90.91 37.09Q860-785.83 860-732v504q0 53.82-37.09 90.91Q785.83-100 732-100H228Z" />
        </svg>
      );
    case "joystickIcon":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill={targetColor}
        >
          <path d="M416-645v-16q-43.24-19.32-69.62-57.73Q320-757.14 320-807.02 320-875 366.66-921q46.65-46 114-46 66.34 0 112.84 46.1T640-807.19q0 49.57-26.38 88.18Q587.24-680.4 544-661v16l254 147q30.27 18.08 47.14 47.08Q862-421.91 862-387v93q0 33.91-16.86 63.42Q828.27-201.08 798-184L544-36q-30.36 17-64.18 17Q446-19 416-36L162-184q-30.28-17.08-47.14-46.58Q98-260.09 98-294v-93q0-34.91 16.86-63.92 16.86-29 47.14-47.08l254-147ZM299-441l181 105 182-105-118-66v70H416v-70l-117 66Z" />
        </svg>
      );
    case "trafficIcon":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill={targetColor}
        >
          <path d="M126-122v6q0 28.67-19 47.33Q88-50 60.06-50q-27.94 0-47.5-19.54T-7-117v-236.46q0-20.07 4.5-39.31Q2-412 10-431l68-150q12-26 36.06-42 24.07-16 52.94-16h324q28.65 0 52.53 16.11Q567.4-606.78 579-581l68 150q8 19 12 38.06 4 19.05 4 38.94v238q0 28.67-19 47.33Q625-50 597.06-50q-27.94 0-47.5-19.54T530-117v-5H126Zm11-324h385l-40.54-89H176.82L137-446Zm60 205q20 0 34-14t14-34q0-20-14-34t-34-14q-20 0-34 14t-14 34q0 20 14 34t34 14Zm262 0q20 0 34-14t14-34q0-20-14-34t-34-14q-20 0-34 14t-14 34q0 20 14 34t34 14Zm177.31-446H262q-26 0-39-16.28t-13-35.81q0-20.41 13.75-36.16Q237.5-791 263-791h379q28.88 0 52.94 16T731-734l67 148q8 19 12.5 38.39Q815-528.23 815-508v286q0 21.57-15.18 36.79Q784.65-170 763.12-170q-21.52 0-36.82-15.21Q711-200.43 711-222v-304l-74.69-161ZM787.7-861H414q-26 0-39-16.28t-13-35.81q0-20.41 13.75-36.16Q389.5-965 415-965h379q28.5 0 52.25 15.5T882-909l68 151q8 19 12.5 38.01Q967-700.97 967-680v287q0 21.15-14.59 36.08Q937.82-342 915.71-342q-22.11 0-37.41-15.21Q863-372.43 863-394v-303.97L787.7-861Z" />
        </svg>
      );
    case "codeIcon":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill={targetColor}
        >
          <path d="M674-146q-26.75 0-44.87-18.29Q611-182.58 611-210.11q0-26.52 17.68-44.21Q646.35-272 674-272h-40q22.3 0 38.15-16.38Q688-304.75 688-327v-35q0-44.22 29.5-77.11T791-479v-3q-45-7-74-39.24T688-598v-36q0-22.3-15.85-38.15Q656.3-688 634-688h40q-26.75 0-44.87-18.29Q611-724.58 611-752.11q0-26.52 17.68-44.21Q646.35-814 674-814h1q58.5 0 98.75 40.75T814-673v35q0 23.95 19.61 39.47Q853.22-583 880.5-583q11.72 0 20.61 9.89Q910-563.22 910-551v142q0 12.22-8.69 22.11Q892.63-377 880-377q-26.78 0-46.39 15T814-323v36q0 59.5-40.25 100.25T675-146h-1Zm-389 0q-58.5 0-98.75-40.75T146-287v-36q0-24-20.03-39-20.02-15-47.47-15-12.02 0-20.26-8.04Q50-393.08 50-406v-148q0-12.92 8.04-20.96Q66.07-583 79-583q26.95 0 46.97-15.53Q146-614.05 146-638v-35q0-59.5 40.25-100.25T285-814q26.75 0 44.88 18.22Q348-777.56 348-751.04q0 27.53-17.62 45.29Q312.75-688 285-688h42q-22.25 0-38.62 15.85Q272-656.3 272-634v36q0 45.68-29.05 78.55T170-483v3.62q44.9 5.29 73.45 38.49Q272-407.7 272-362v35q0 22.25 16.38 38.62Q304.75-272 327-272h-42q26.75 0 44.88 18.22Q348-235.56 348-209.04q0 27.53-17.62 45.29Q312.75-146 285-146Z" />
        </svg>
      );
    case "tailIcon":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="600mm"
          height="420mm"
          viewBox="0 0 29700 21000"
        >
          <path
            fill={theme.secondary}
            d="M22885.15 12631.91c0,-1016.58 -445.67,-1942.32 -1175.77,-2636.51 -1610.53,-367.88 -3336.22,-567.36 -5130.98,-567.36 -716.12,0 -1310.9,-5.26 -2001.85,45.14 -184.8,10.75 -4016.68,265.6 -4224.5,265.6 -2014.69,0 -4059.2,-1074.09 -3126.06,-3405.33 460.25,-1149.81 2163.84,-2612.17 3149.44,-3373.82l-4102.9 -22.51c-1673.81,1148.37 -3293.42,2384.01 -3393.24,4523.7 -111.62,2392.68 807.41,3814.45 2426.38,5104.1 2797.75,2248 6874.19,3136.89 11272.73,3136.89 1859.06,0 3644.01,-214.03 5303.63,-607.54 627.1,-671.44 1003.12,-1528.58 1003.12,-2462.36z"
          />
          <path
            fill={theme.primary}
            d="M21872.27 9995.4c730.11,694.2 1175.78,1619.94 1175.78,2636.52 0,933.78 -376.02,1790.92 -1003.12,2462.36 2267.05,-537.53 4979.42,-2059.36 5733.82,-3941.95 -111.83,39.88 -222.34,74.06 -331.84,102.88 -96.09,216.91 -285.77,487.84 -464.59,682.75l107.11 -329.83 32.96 -279.93c-19.43,5.75 -226.42,29.87 -302.13,36.49 -115.14,259.64 -333.44,519.53 -524.62,755.38l108.94 -393.3 38.58 -350.2c-81.06,-2.77 -249.88,-8 -331.22,-15.51 -148.31,343.27 -414.05,620.28 -657.42,932.5l138.47 -530.98 46.3 -461.5c-1048.57,-213.58 -2253.08,-762.76 -3767.02,-1305.68z"
          />
        </svg>
      );
    case "arrowBackIcon":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill={targetColor}
        >
          <path d="m333-426 184 184q16 16 16 38t-16 38q-16 16-38 16t-38-16L165-442q-8-8-11.5-17t-3.5-20q0-11 3.5-20t11.5-17l278-278q16-16 38-16t38 16q16 16 16 38t-16 38L333-532h446q22 0 37.5 15.5T832-479q0 22-15.5 37.5T779-426H333Z" />
        </svg>
      );
    case "arrowForwardIcon":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill={targetColor}
        >
          <path d="M676-443H181q-16 0-26.5-10.5T144-480q0-15 10.5-25.5T181-516h495L454-738q-11-11-11-26t11-26q10-11 25.5-11t26.5 11l285 285q5 5 8 11.5t3 13.5q0 8-3 14.5t-8 11.5L507-170q-12 11-27 11t-25-11q-11-11-11-26t11-26l221-221Z" />
        </svg>
      );
    case "attachmentIcon":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill={targetColor}
        >
          <path d="M660-480v-236h60v236h-60ZM430-253q-26-12-43-36.47T370-346v-370h60v463Zm43 173q-103.17 0-175.58-71.5Q225-223 225-326v-380q0-72.5 51.5-123.25T400-880q72 0 123.5 50.75T575-706v346h-60v-346q0-48-33.5-81t-81.71-33q-48.21 0-81.5 33.06T285-706v380q0 78 54.97 132T473-140q37 0 69.5-13t57.5-36v74q-28 17-60 26t-67 9Zm187-40v-120H540v-60h120v-120h60v120h120v60H720v120h-60Z" />
        </svg>
      );
    case "phoneIcon":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill={targetColor}
        >
          <path d="M815.81-520q-19.62 0-33.8-13.35Q767.83-546.7 763-568q-13-82-73.67-141.95Q628.66-769.91 548-784q-21.3-3.81-34.65-18.7Q500-817.58 500-837q0-20 14.5-33.5T548-881q122.47 15.12 209.65 103.02Q844.84-690.08 861-568q2 19.2-10.88 33.6-12.88 14.4-34.31 14.4Zm-175.27 0q-14.75 0-28.65-11.5Q598-543 591-562q-8-17-20.59-29.23Q557.82-603.45 541-611q-20.45-7-30.73-21Q500-646 500-661.35q0-23.87 16.5-38.76 16.5-14.89 38.38-9.15 49.63 14.4 84.8 49.65Q674.86-624.36 689-576q6 21.11-9.23 38.56Q664.53-520 640.54-520ZM777.12-99q-133.22-9-252.17-63.5Q406-217 311-311q-95-94-149-214.28T99-778.24q-2-35.9 20.93-60.33T179-863h149q40.39 0 65.19 19Q418-825 428-788l25 97q5 27-2 50.5T425-601l-106 94q15 26 35 52t46.97 52.98Q426-378 449-360q23 18 44 29l113-104q18-18 38.31-23 20.32-5 46.69 1l96 24q37.13 11 56.06 34Q862-376 862-338v158q0 36.57-25 59.79Q812-97 777.12-99ZM258-624l66-58-14.16-53H231q3 32 9.96 58.44Q247.91-650.12 258-624Zm356 359q28.1 11.24 58.05 18.12Q702-240 734-232v-82l-55-15-65 64ZM258-624Zm356 359Z" />
        </svg>
      );
    case "likeIcon":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill={targetColor}
        >
          <path d="M821-610q48 0 88 40.3t40 87.7v-.56q0 10.39-1.5 20.47Q946-452 941-445L812-141q-14 34-51.29 53.5T687-68l-460-1v-541l182-185q34-34 75.5-46t76 4q32.5 16 43 58.5T601-687l-17 77h237Zm-481 68v345h357l122-289v4H436l40-195-136 135ZM117-69q-55 0-91.5-36.45T-11-197v-285q0-53.7 36.5-90.85Q62-610 117-610h110v128H117v285h110v128H117Zm223-128v-345 60-9 294Z" />
        </svg>
      );
    case "sendIcon":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill={targetColor}
        >
          <path d="M160-173q-18 8-34.5-2.5T109-206v-198l314-78-314-81v-195q0-19 16.5-29.5T160-791l653 275q21 10 21 34.5T813-448L160-173Z" />
        </svg>
      );
    case "repeatIcon":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill={targetColor}
        >
          <path d="m322-166-6.22-6.22Q334-155 335-130.5T317.48-88Q299-71 275-71q-24 0-41.95-17.91l-91.01-90.64q-8.48-8.45-13.26-20.81Q124-212.73 124-225q0-12.27 4.78-24.64 4.78-12.36 13.26-20.81l92.01-90.64Q252-379 276-379q24 0 41.48 17Q336-344 335-319t-19.22 42.22L322-284h374v-98q0-24.3 17.29-41.65Q730.58-441 754.79-441t41.71 17.35Q814-406.3 814-382v88q0 53.7-37.15 90.85Q739.7-166 686-166H322Zm317-500H264v99q0 24.3-17.29 41.65Q229.42-508 205.21-508t-41.71-17.35Q146-542.7 146-567v-89q0-53.7 37.15-90.85Q220.3-784 274-784h365l6.22 6.22Q627-795 626.5-819t17.02-42q18.48-18 42.98-18 24.5 0 41.45 17.91l91.01 90.64q9.48 9.45 14.26 21.31Q838-737.27 838-725q0 13.27-4.78 25.14-4.78 11.86-14.26 21.31l-92.01 90.64Q710-571 686-571q-24 0-41.48-18Q627-607 628-632t18.96-41.96L639-666Z" />
        </svg>
      );
    case "repeatOneIcon":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill={targetColor}
        >
          <path d="M468.16-518h-9.83q-14.33 0-24.83-10.3Q423-538.6 423-553q0-14.4 10.3-24.7Q443.6-588 458-588h15q27.3 0 45.65 18.85Q537-550.3 537-524v116.91q0 14.09-9.8 24.09T503-373q-14.33 0-24.58-10.3-10.26-10.3-10.26-24.7v-110ZM322-166l-6.22-6.22Q334-155 335-130.5T317.48-88Q299-71 275-71q-24 0-41.95-17.91l-91.01-90.64q-8.48-8.45-13.26-20.81Q124-212.73 124-225q0-12.27 4.78-24.64 4.78-12.36 13.26-20.81l92.01-90.64Q252-379 276-379q24 0 41.48 17Q336-344 335-319t-19.22 42.22L322-284h374v-98q0-24.3 17.29-41.65Q730.58-441 754.79-441t41.71 17.35Q814-406.3 814-382v88q0 53.7-37.15 90.85Q739.7-166 686-166H322Zm317-500H264v99q0 24.3-17.29 41.65Q229.42-508 205.21-508t-41.71-17.35Q146-542.7 146-567v-89q0-53.7 37.15-90.85Q220.3-784 274-784h365l6.22 6.22Q627-795 626.5-819t17.02-42q18.48-18 42.98-18 24.5 0 41.45 17.91l91.01 90.64q9.48 9.45 14.26 21.31Q838-737.27 838-725q0 13.27-4.78 25.14-4.78 11.86-14.26 21.31l-92.01 90.64Q710-571 686-571q-24 0-41.48-18Q627-607 628-632t18.96-41.96L639-666Z" />
        </svg>
      );
    case "refreshIcon":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill={targetColor}
        >
          <path d="M476.28-145Q338-145 239.5-243T141-479q0-138 98.5-237T476-815q74.29 0 136.65 30Q675-755 721-705v-61q0-20.3 13.59-34.65Q748.18-815 769.29-815q21.11 0 35.41 14.35Q819-786.3 819-766v197q0 25.9-19.05 44.95Q780.9-505 755-505H556q-19.87 0-33.94-14.37Q508-533.74 508-554.07q0-19.9 14.35-33.92Q536.7-602 557-602h84q-30-38-72.4-60.5Q526.2-685 476-685q-86 0-146.5 59.92Q269-565.17 269-479q0 86.33 60.5 146.17Q390-273 476-273q63 0 112.11-36.81Q637.22-346.63 657-403q11.62-22.16 35.81-33.08Q717-447 740.77-440q25.23 7 38.73 25 13.5 18 6.5 40-30 100-115.19 165t-194.53 65Z" />
        </svg>
      );
    case "playCircleIcon":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill={targetColor}
        >
          <path d="m444-334 164-105q23-14.93 23-41.46Q631-507 608-521L444-626q-24-14-48.5-.11Q371-612.23 371-584v208q0 28.23 24.5 42.11Q420-320 444-334Zm36.28 284q-88.92 0-167.75-33.1-78.82-33.11-137.57-91.86T83.1-312.49Q50-391.28 50-480.46q0-89.44 33.16-167.49 33.17-78.05 92.18-136.94 59.01-58.89 137.53-92Q391.39-910 480.46-910q89.43 0 167.52 33.09 78.09 33.1 136.96 91.97 58.87 58.87 91.97 137.21Q910-569.38 910-480.19t-33.11 167.52q-33.11 78.32-92 137.33Q726-116.33 647.72-83.16 569.45-50 480.28-50Zm-.51-128q126.74 0 214.49-87.51Q782-353.03 782-479.77t-87.51-214.49Q606.97-782 480.23-782t-214.49 87.51Q178-606.97 178-480.23t87.51 214.49Q353.03-178 479.77-178Zm.23-302Z" />
        </svg>
      );
    case "pauseCircleIcon":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill={targetColor}
        >
          <path d="M385.37-322q19.61 0 34.12-14.05Q434-350.1 434-369.96v-219.76q0-19.85-14.09-34.07-14.09-14.21-34-14.21t-34.41 14.05Q337-609.9 337-590.04v219.76q0 19.86 14.17 34.07t34.2 14.21Zm189.7 0q19.9 0 33.92-14.05Q623-350.1 623-369.96v-219.76q0-19.85-13.89-34.07Q595.23-638 575.61-638 556-638 541-623.95t-15 33.91v219.76q0 19.86 14.37 34.07t34.7 14.21ZM480.28-50q-88.92 0-167.75-33.1-78.82-33.11-137.57-91.86T83.1-312.49Q50-391.28 50-480.46q0-89.44 33.16-167.49 33.17-78.05 92.18-136.94 59.01-58.89 137.53-92Q391.39-910 480.46-910q89.43 0 167.52 33.09 78.09 33.1 136.96 91.97 58.87 58.87 91.97 137.21Q910-569.38 910-480.19t-33.11 167.52q-33.11 78.32-92 137.33Q726-116.33 647.72-83.16 569.45-50 480.28-50Zm-.51-128q126.74 0 214.49-87.51Q782-353.03 782-479.77t-87.51-214.49Q606.97-782 480.23-782t-214.49 87.51Q178-606.97 178-480.23t87.51 214.49Q353.03-178 479.77-178Zm.23-302Z" />
        </svg>
      );
    case "fileIcon":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill={targetColor}
        >
          <path d="M273.72-50q-52.45 0-90.08-37.09Q146-124.18 146-178v-604q0-53.83 37.79-90.91Q221.57-910 274-910h258q25.7 0 48.98 9.09Q604.27-891.83 623-872l152 151q19.91 19.57 29.46 42.91Q814-654.75 814-629v451q0 53.82-37.8 90.91Q738.4-50 685.96-50H273.72ZM507-668v-114H274v604h412v-426H571q-27.3 0-45.65-18.35Q507-640.7 507-668ZM274-782v178-178 604-604Z" />
        </svg>
      );
    case "boardIcon":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill={targetColor}
        >
          <path d="M142.67-58.67q-33.73 0-58.87-25.13-25.13-25.14-25.13-58.87v-594.66q0-33.73 25.13-58.87 25.14-25.13 58.87-25.13H308l144.67-131q12.09-10.34 27.21-10.34 15.12 0 27.45 10.34l144.67 131h165.33q33.73 0 58.87 25.13 25.13 25.14 25.13 58.87v594.66q0 33.73-25.13 58.87-25.14 25.13-58.87 25.13H142.67Zm0-84h674.66v-594.66H142.67v594.66ZM236-278.27v-323.46q0-17.16 12.25-29.71Q260.5-644 278.33-644h404q17.19 0 29.43 12.41Q724-619.17 724-601.73v323.46q0 17.81-12.24 30.04T682.33-236h-404q-17.83 0-30.08-12.23T236-278.27Z" />
        </svg>
      );
    case "homeIcon":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill={targetColor}
        >
          <path d="M148-180v-389q0-17.25 7.875-32.5T178-628l259-194q18.68-14 42.84-14Q504-836 524-822l259 194q13.25 11.25 21.125 26.5T812-569v389q0 30.75-21.125 51.875T739-107H602q-15.75 0-26.375-10.625T565-144v-220q0-14.75-10.625-25.375T529-400h-97q-15.75 0-26.375 10.625T395-364v220q0 15.75-10.625 26.375T359-107H221q-30.75 0-51.875-21.125T148-180Z" />
        </svg>
      );
    case "toolsIcon":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill={targetColor}
        >
          <path d="M774-16q-11.91 0-23.95-5Q738-26 729-35L527-238q-9.18-7.93-14.59-20.68T507-283q0-11.57 5.41-24.32T527-329l102-103q8.93-9.18 21.68-15.09T675-453q11.57 0 24.82 5.91T721-432l203 203q9 9 14 21.07 5 12.06 5 24 0 11.93-5.41 25.03-5.41 13.1-13.59 21.9L820-34q-8.93 8.18-21.68 13.09T774-16Zm1-133 35-35-137-136-34 34 136 137ZM189-17q-13 0-25.64-5.5Q150.71-28 142-37L39-139q-9-8.71-13.5-20.36Q21-171 21-183t5-24.6q5-12.6 14-20.4l202-202h113l46-46-155-155h-65L7-803l146-146 173 173v65l155 155 113-113-46-48 54-54h-89l-50-50 178-179 51 51v89l30-31 174.93 176.93Q918-693 931-665.07q13 27.92 13 57.07 0 34.65-16.5 66.32Q911-510 887-486L776-597l-54 54-46-47-241 240v113L234-36q-7.93 9-20.18 14T189-17Zm-1-131 134-134v-35h-36L154-184l34 36Zm0 0-34-36 17.03 17.97L188-148Zm587-1 35-35-35 35Z" />
        </svg>
      );
    case "closeIcon":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill={targetColor}
        >
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="M480-390 334-244q-20 20-45 19.5T245-245q-20-20-20-45t20-45l145-145-146-147q-20-20-19.5-45t20.5-45q19-20 44.5-20t45.5 20l145 146 146-146q20-20 45.5-20t44.5 20q20 20 20 45t-20 45L570-480l146 146q20 20 20 44.5T716-245q-19 20-44.5 20T626-245L480-390Z"
          />
        </svg>
      );
    case "stickersIcon":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill={targetColor}
        >
          <path d="M480.05-2Q321-2 209.5-49.5T98-190q0-33.47 21-61.23Q140-279 176-303l101 87q-26 8-35.5 16.11T227-190q10 14.1 73.54 37.05Q364.08-130 480.04-130 596-130 660-152.95q64-22.95 74-37.05-5-1.78-14.5-9.89Q710-208 684-216l100-87q36 24 57 51.77 21 27.76 21 61.23 0 93-111.45 140.5T480.05-2Zm.03-191q-19.08 0-36.58-7T412-219L146-449q-18-16-27.5-35.5T109-526v-100q0-24 10-44t27-36l265-227q14-12 31.75-18.5t37-6.5q19.25 0 37.17 7 17.91 7 32.08 19l265 227q16 16 26.5 35.5T851-627v102q0 22-10 41.5T814-448L548-218q-14 12.5-31.42 18.75t-36.5 6.25Zm-1.02-238q10.94 0 21.44-4 10.5-4 20.26-11.42L736-628 505-835q-4-4-10.5-7t-13.63-3q-6.14 0-12 3-5.87 3-11.87 7L224-629l212 183q10 7 21.06 11 11.05 4 22 4ZM358.89-574Q380-574 395-588.39q15-14.38 15-35.5 0-21.11-14.89-35.61-14.88-14.5-36-14.5-21.11 0-35.61 14.39-14.5 14.38-14.5 35.5 0 21.11 14.39 35.61 14.38 14.5 35.5 14.5ZM405-517q45 24 94 17.5t85.51-43.5Q618-575 625-617.5t-9-85.5L405-517Zm75.89-165q21.11 0 35.61-14.89 14.5-14.88 14.5-36 0-21.11-14.39-35.61-14.38-14.5-35.5-14.5Q460-783 445-768.61q-15 14.38-15 35.5Q430-712 444.89-697q14.88 15 36 15Zm-.89 44Z" />
        </svg>
      );
    default:
      return null;
  }
};

export default IconAdapter;
