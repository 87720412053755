import React, { useState, useEffect, useRef } from "react";
import Cookies from "js-cookie";

import axios from "axios";

import "./App.css";

import Main from "./Main";
import Auth from "./Auth";
import NotificationComponent from "./components/NotificationComponent";
import Placeholder from "./screens/Placeholder";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    requestNotificationPermission();
    const token = Cookies.get("authToken");
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  const requestNotificationPermission = () => {
    if (!('Notification' in window)) {
      alert('Ваш браузер не поддерживает уведомления');
    } else if (Notification.permission === 'granted') {
      console.log('Разрешение уже получено');
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          console.log('Разрешение получено');
        }
      });
    }
  }

  const [subscription, setSubscription] = useState(null);

  const initialized = useRef(null);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      if (!('serviceWorker' in navigator)) {
        console.log("Service workers aren't supported");
        return;
      }

      if (!('PushManager' in window)) {
        console.log("Push isn't supported");
        return;
      }
      if (!subscription) {
        navigator.serviceWorker.ready.then((registration) => {
          registration.pushManager.getSubscription()
            .then((existingSubscription) => {
              if (existingSubscription) {
                setSubscription(existingSubscription);
              } else {
                subscribeUser(registration);
              }
            });
        });
      }

      return () => {
        if (subscription) {
          subscription.unsubscribe();
        }
      };
    }
  }, []);

  const subscribeUser = async (registration) => {
    try {
      const new_subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: 'BA-H2BaaXhr8-drbjPTskkROPKudB-mDMlkjgXGzT1hFuI3CJ4Qacs4aZGleZCcBp0xTzDe9YLY5j5kAAdkzJdw'
      });

      setSubscription(new_subscription);

      const formData = new FormData();
      formData.append("secret", Cookies.get("authToken"));
      formData.append("subscription_data", JSON.stringify(new_subscription));

      await axios.post(
        `https://${window.location.hostname}/api/subscribe`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
        .then(({ data }) => {
          console.log("Subscribed");
        })
        .catch((error) => {
          const { status, data } = error.response;
          console.error("Response Status:", status, "Response Data:", data);
        });
    } catch (error) {
      console.error('Failed to subscribe the user: ', error);
    }
  };

  return (
    <div className="App">
      <div className="background-gradient"></div>
      <div className="stars">
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
      </div>
      <div className="area" >
        <ul className="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div >
      <NotificationComponent />
      {isAuthenticated ? <Placeholder><Main /></Placeholder> : <Auth />}
    </div>
  );
};

export default App;
