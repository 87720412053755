import React, { useState, useEffect, useRef } from 'react';
import './Guilds.css'

import Recorder from 'opus-recorder';

import { useSocket } from "../context/SocketContext.js";

import axios from "axios";
import Cookies from "js-cookie";

import TextChannelTypeIcon from '../assets/images/chat.svg'
import VoiceChannelTypeIcon from '../assets/images/sound.svg'

import sendIcon from "../assets/images/send.svg";

const Guilds = ({ my_user_id }) => {
    const [createdGuildInvite, setCreatedGuildInvite] = useState(null);

    const [guildModalOpened, setGuildModalOpened] = useState(false);
    const [modalEnteredCode, setModalEnteredCode] = useState('');
    const [modalEnteredName, setModalEnteredName] = useState('');

    const [curChannel, setCurChannel] = useState(null);

    const [curGuild, setCurGuild] = useState(null);
    const [curGuildChannels, setCurGuildChannels] = useState(null);

    const [myGuildsData, setMyGuildsData] = useState(null);

    const refreshMyGuilds = async () => {
        const formData = new FormData();
        formData.append("session", Cookies.get("authToken"));
        try {
            const response = await axios.post(
                `https://${window.location.hostname}/api/v1/client/get_my_guilds`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            if (response) {
                console.log("Success get guilds info");
                setMyGuildsData(response.data);
                const checker_guild = response.data.find(guild => guild.id === parseInt(localStorage.getItem("activeGuildId")))
                if (checker_guild) {
                    setCurGuild(checker_guild);
                }
            }
        } catch (error) {
            if (error.response) {
                console.error(
                    "Response Status:",
                    error.response.status,
                    "Response Data:",
                    error.response.data
                );
            }
        }
    };

    const refreshCurGuildChannels = async () => {
        const formData = new FormData();
        formData.append("session", Cookies.get("authToken"));
        formData.append("guild_id", curGuild.id);
        await axios.post(
            `https://${window.location.hostname}/api/v1/client/get_guild_channels`,
            formData,
            {
                headers: { "Content-Type": "multipart/form-data", },
            }
        ).then(({ data }) => {
            console.log("Success get guild channels info");
            setCurGuildChannels(data);
        }).catch(error => {
            const { status, data } = error.response;
            console.error("Response Status:", status, "Response Data:", data);
        });
    };

    const createGuildInvite = async () => {
        const formData = new FormData();
        formData.append("session", Cookies.get("authToken"));
        formData.append("guild_id", curGuild.id);
        await axios.post(
            `https://${window.location.hostname}/api/v1/client/create_guild_invite`,
            formData,
            {
                headers: { "Content-Type": "multipart/form-data", },
            }
        ).then(({ data }) => {
            console.log("Created a guild invite");
            setCreatedGuildInvite(data.secret);
        }).catch(error => {
            const { status, data } = error.response;
            console.error("Response Status:", status, "Response Data:", data);
        });
    };

    const socketRef = useRef(null);
    const socketVideoRef = useRef(null);

    const [connectedToVideoSocket, setConnectedToVideoSocket] = useState(false);

    const [connected, setConnected] = useState(false);
    const [isConnecting, setIsConnecting] = useState(false);

    const audioRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const mediaScreenRecorderRef = useRef(null);

    const videoRef = useRef(null);

    const [videoUrl, setVideoUrl] = useState(null);
    const [videoObj, setVideoObj] = useState(null);

    const [activeChannel, setActiveChannel] = useState(null);

    const connectToRoom = async (channel_id) => {
        if (connected || isConnecting) return;
        setIsConnecting(true)
        playSound('connect')
        try {
            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            audioRef.current = audioContext;

            const microphoneStream = await navigator.mediaDevices.getUserMedia({ audio: true }, { mimeType: 'audio/webm; codecs=opus' });
            const mediaRecorder = new MediaRecorder(microphoneStream);
            mediaRecorderRef.current = mediaRecorder;

            mediaRecorder.ondataavailable = async (event) => {
                if (event.data.size > 0 && socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
                    const arrayBuffer = await event.data.arrayBuffer();
                    socketRef.current.send(arrayBuffer);
                }
            };

            socketRef.current = new WebSocket(`wss://${window.location.hostname}/api/ws/voice/${Cookies.get("authToken")}/${channel_id}`);

            socketRef.current.onopen = () => {
                playSound('opened')
                setConnected(true);
                setIsConnecting(false)
                setActiveChannel(channel_id);
                console.log("Connected to the server");
                refreshCurGuildChannels();

                const sendAudioChunks = () => {
                    setInterval(() => {
                        mediaRecorder.stop();
                        mediaRecorder.start();
                    }, 300);
                };

                sendAudioChunks();
            };

            socketRef.current.onmessage = async (event) => {
                const arrayBuffer = await event.data.arrayBuffer();
                try {
                    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
                    playSocketChunk(audioBuffer);
                } catch (error) {
                    console.error("Ошибка декодирования аудио:", error);
                }
            };
        } catch (error) {
            console.error("Error", error);
            setIsConnecting(false)
            playSound('error');
        }
    };

    const shareVideo = async (channel_id) => {
        if (!connected || isConnecting) return;
        playSound('connect')
        try {
            const screenStream = await navigator.mediaDevices.getDisplayMedia({
                video: {
                    frameRate: 60,
                    width: { ideal: 1920 },
                    height: { ideal: 1080 },
                },
                audio: true
            });

            const mediaScreenRecorder = new MediaRecorder(screenStream);
            mediaScreenRecorderRef.current = mediaScreenRecorder;
            mediaScreenRecorder.ondataavailable = async (event) => {
                if (event.data.size > 0 && socketVideoRef.current && socketVideoRef.current.readyState === WebSocket.OPEN) {
                    const arrayBuffer = await event.data.arrayBuffer();
                    socketVideoRef.current.send(arrayBuffer);
                }
            };

            socketVideoRef.current = new WebSocket(`wss://${window.location.hostname}/api/ws/video/${Cookies.get("authToken")}/${activeChannel}`);
            socketVideoRef.current.onopen = () => {
                console.log("Connected to the video server");
                setConnectedToVideoSocket(true);
                const sendVideoChunks = () => {
                    setInterval(() => {
                        mediaScreenRecorder.stop();
                        mediaScreenRecorder.start();
                    }, 1000);
                };

                sendVideoChunks();
            };

            socketVideoRef.current.onmessage = async (event) => {
                const arrayBuffer = await event.data.arrayBuffer();
                const blob = new Blob([arrayBuffer], { type: 'video/webm; codecs=vp8' });
                setVideoObj(blob);
                const videoUrl = URL.createObjectURL(blob);
                setVideoUrl(videoUrl);
            };
            
            socketVideoRef.current.onclose = () => {
                console.log("Disconnected from the server");
                setConnectedToVideoSocket(false);
                mediaScreenRecorderRef.current.stop();
            };
        } catch (error) {
            console.error("Error", error);
            setIsConnecting(false)
            playSound('error');
        }
    };

    useEffect(() => {
        refreshMyGuilds();
    }, []);

    useEffect(() => {
        if (curGuild) {
            refreshCurGuildChannels();
        }
    }, [curGuild]);

    const playSound = (sound_name) => {
        const audioElement = document.getElementById('wavAudio');
        audioElement.src = `/${sound_name}.wav`;
        audioElement.play();
    }

    const playSocketChunk = (audioBuffer) => {
        const source = audioRef.current.createBufferSource();
        source.buffer = audioBuffer;
        source.connect(audioRef.current.destination);
        source.start();
    };

    const handleJoinGuild = async () => {
        const formData = new FormData();
        formData.append("session", Cookies.get("authToken"));
        formData.append("guild_secret", modalEnteredCode);
        await axios.post(
            `https://${window.location.hostname}/api/v1/client/join_guild`,
            formData,
            {
                headers: { "Content-Type": "multipart/form-data", },
            }
        ).then(({ data }) => {
            console.log("Joined guild");
            refreshMyGuilds();
            setGuildModalOpened(false);
        }).catch(error => {
            const { status, data } = error.response;
            console.error("Response Status:", status, "Response Data:", data);
        });
    }

    const handleCreateGuild = async () => {
        const formData = new FormData();
        formData.append("session", Cookies.get("authToken"));
        formData.append("guild_name", modalEnteredName);
        await axios.post(
            `https://${window.location.hostname}/api/v1/client/create_guild`,
            formData,
            {
                headers: { "Content-Type": "multipart/form-data", },
            }
        ).then(({ data }) => {
            console.log("Created guild");
            refreshMyGuilds();
            setGuildModalOpened(false);
        }).catch(error => {
            const { status, data } = error.response;
            console.error("Response Status:", status, "Response Data:", data);
        });
    }

    const { lastMessage } = useSocket();

    useEffect(() => {
        if (lastMessage && lastMessage.data) {
            const parsedData = JSON.parse(lastMessage.data);
            if (parsedData.type === "update" && parsedData.message === "channel") {
                refreshMyGuilds();
            }
        }
    }, [lastMessage]);

    const canvasRef = useRef(null);
    const [lastFrame, setLastFrame] = useState(null);
    const videoProcessRef = useRef(null);

    useEffect(() => {
        if (videoObj && videoProcessRef.current && canvasRef.current) {
            const videoElement = videoProcessRef.current;
            const canvas = canvasRef.current;

            const handleLoadedMetadata = () => {
                const duration = videoElement.duration;
                if (isFinite(duration)) {
                    videoElement.currentTime = duration - 0.01;
                }
                canvas.width = videoElement.videoWidth;
                canvas.height = videoElement.videoHeight;
            };

            const handleSeeked = () => {
                const ctx = canvas.getContext('2d');
                if (ctx) {
                    ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
                    const frameDataUrl = canvas.toDataURL('image/png');
                    setLastFrame(frameDataUrl);
                }
            };

            // Назначаем события
            videoElement.addEventListener('loadedmetadata', handleLoadedMetadata);
            videoElement.addEventListener('seeked', handleSeeked);

            // Если метаданные уже загружены, вызываем сразу
            if (videoElement.readyState >= 1) {
                handleLoadedMetadata();
            }

            // Убираем обработчики при размонтировании
            return () => {
                videoElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
                videoElement.removeEventListener('seeked', handleSeeked);
            };
        }
    }, [videoObj]);

    return (
        <div className="Guilds">
            {/* Hidden video element for processing */}
            <video ref={videoProcessRef} src={videoUrl} style={{ display: 'none' }} controls>
                Your browser does not support the video tag.
            </video>
            {/* Canvas to capture the last frame */}
            <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
            <audio id="wavAudio" src="" type="audio/wav"></audio>
            <div className="guild-modal" style={guildModalOpened ? { display: "block" } : { display: "none" }}>
                <div className="guild-modal-content">
                    <div className="modal-join-guild">
                        <h2>Присоединиться</h2>
                        <p>Присоединяйтесь к серверам своих друзей или коллег используя код приглашения</p>
                        <div className="modal-hor-hold">
                            <input className="kitsunet-design-input-field" type="text" placeholder='Код приглашения' onChange={(e) => setModalEnteredCode(e.target.value)} required />
                            <button className='guild-chat-send-button' type="submit" onClick={handleJoinGuild}>
                                <img className="send-icon" src={sendIcon} alt="send"></img>
                            </button>
                        </div>
                    </div>
                    <div className="modal-create-guild">
                        <h2>Создать</h2>
                        <p>Или создайте свой собственный островок спокойствия просто введя название сервера</p>
                        <div className="modal-hor-hold">
                            <input className="kitsunet-design-input-field" type="text" placeholder='Название сервера' onChange={(e) => setModalEnteredName(e.target.value)} required />
                            <button className='guild-chat-send-button' type="submit" onClick={handleCreateGuild}>
                                <img className="send-icon" src={sendIcon} alt="send"></img>
                            </button>
                        </div>

                    </div>
                    <div className='close modal-close' onClick={() => setGuildModalOpened(false)}>&times;</div>
                </div>
            </div>

            <div className="guilds-list-holder">
                {myGuildsData && myGuildsData.map((guild) => (<>
                    <div className="guild-list-item" key={guild.id} onClick={() => { setCurGuild(guild); localStorage.setItem("activeGuildId", guild.id); }}>
                        {guild.guild_image ? (<>
                            <div className={`guild-list-item-img ${curGuild && guild.id === curGuild.id ? 'guild-list-item-active' : ''}`}>
                                <img className="guild-list-item-img-src" src={guild.guild_image} alt="" />
                            </div>
                        </>) : (<>
                            <h1 className={`guild-list-item-img-placer ${curGuild && guild.id === curGuild.id ? 'guild-list-item-active' : ''}`}>{guild.guild_name[0]}</h1>
                        </>)}

                    </div>
                </>))}
                <div className="guild-list-item" onClick={() => { setGuildModalOpened(true) }}>
                    <h1 className='guild-list-item-img-placer'>+</h1>
                </div>
            </div>
            {curGuild && (<div className="guild-holder">
                <div className="guild-left-panel">
                    {connectedToVideoSocket && (
                        <div className="channel-media-player-holder">
                        <div className="video-stream-holder">
                            <video ref={videoRef} src={videoUrl} autoPlay></video>
                            {lastFrame && (
                                <img src={lastFrame} alt="Last Frame" />
                            )}
                        </div>
                    </div>
                    )}
                    
                    {curChannel ? (<>
                        <div className="guild-chat-label">
                            <img src={TextChannelTypeIcon} alt="" className="channel-type-icon" />
                            <h2>{curChannel.channel_name}</h2>
                        </div>
                        <div className="guild-chat-holder">

                        </div>
                        <div className="guild-chat-input">
                            <input className="kitsunet-design-input-field" type="text" />
                            <button className='guild-chat-send-button' type="submit">
                                <img className="send-icon" src={sendIcon} alt="send"></img>
                            </button>
                        </div>
                    </>) : (<>
                        <div className="guild-chat-empty-holder">
                            <h1>Выберите любой канал</h1>
                        </div>
                    </>)}

                </div>
                <div className="guild-right-panel">
                    <div className="guild-info-holder">
                        {curGuild.guild_image ? (<>
                            <img className="guild-list-item-img-src" src={curGuild.guild_image} alt="" />
                        </>) : (<>
                            <h1 className='guild-info-item-img-placer'>{curGuild.guild_name[0]}</h1>
                        </>)}
                        <h1>{curGuild.guild_name}</h1>
                    </div>

                    {curGuild.owner_id === my_user_id && (
                        <div className="guild-create-invite">
                            <button className='kitsunet-design-input-button' type="submit" onClick={createGuildInvite}>
                                <h3>Создать приглашение на сервер</h3>
                            </button>
                            {createdGuildInvite && (
                                <div className='guild-secret-holder'>
                                    <h5>Секретный код:</h5>
                                    <h4 className='guild-secret-selecter'>{createdGuildInvite}</h4>
                                </div>
                            )}
                        </div>
                    )}

                    <div className="guild-channels-holder">
                        {curGuildChannels && curGuildChannels.map((channel) => (<>
                            {channel.channel_type === "voice" && (<>
                                <div className="guild-voice-channel-item guild-channel-item" onClick={() => { connectToRoom(channel.id); }}>
                                    <div className="guild-channel-item-main-info">
                                        <img src={VoiceChannelTypeIcon} alt="" className="channel-type-icon" />
                                        <h3>{channel.channel_name}</h3>
                                    </div>
                                    {channel.current_joined_users.length > 0 && (
                                        <div className="channel-connected-users-container">
                                            {channel.current_joined_users.map((user) => (
                                                <div className="connected-user-item">
                                                    <img src={`https://${window.location.hostname}/api/storage/avatars/${user.avatar?.split(";")[user.avatar?.split(";").length - 1] || 'avatar_placeholder.png'}`} alt="" />
                                                </div>))}
                                        </div>)}
                                    {connected && (<button className='kitsunet-design-input-button' onClick={shareVideo}>Поделиться экраном</button>)}
                                </div>
                            </>)}
                            {channel.channel_type === "text" && (<>
                                <div className="guild-text-channel-item guild-channel-item">
                                    <div className="guild-channel-item-main-info">
                                        <img src={TextChannelTypeIcon} alt="" className="channel-type-icon" />
                                        <h3>{channel.channel_name}</h3>
                                    </div>
                                </div>
                            </>)}
                        </>))}
                    </div>
                </div>
            </div>)}
        </div>
    );
};

export default Guilds;