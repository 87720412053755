import React from 'react';
// import ConfirmationDialog from '../components/ConfirmationDialog.js';
import Cookies from "js-cookie";
import axios from "axios";

const Test = () => {

  const requestNotificationPermission = () => {
    if (!('Notification' in window)) {
      alert('Ваш браузер не поддерживает уведомления');
    } else if (Notification.permission === 'granted') {
      console.log('Разрешение уже получено');
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          console.log('Разрешение получено');
        }
      });
    }
  }

    const resetSub = () => {
        navigator.serviceWorker.ready.then((registration) => {
            registration.pushManager.getSubscription()
                .then((existingSubscription) => {
                    existingSubscription.unsubscribe()
                });
        });
    }

    const handleClick = async () => {
        const formData = new FormData();
        formData.append("session_token", Cookies.get("authToken"));
        await axios
          .post(
            `https://${window.location.hostname}/api/send_test_notification`,
            formData,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          )
          .then(({ data }) => {
            console.log("Sended");
          })
          .catch((error) => {
            const { status, data } = error.response;
            console.error("Response Status:", status, "Response Data:", data);
          });
      };

    return (
        <div className="Test">
            <button className="kitsunet-design-input-button" onClick={resetSub}>Удалить пуш регистрацию</button>
            <button className="kitsunet-design-input-button" onClick={handleClick}>Отправить тестовый пуш</button>
            <button className="kitsunet-design-input-button" onClick={requestNotificationPermission}>Запрос на уведомления</button>
        </div>
    );
};

export default Test;